import * as React from "react";

import "./PasswordStrengthTip.scss";
export interface PasswordStrengthTipProps {
  passwordErrorFirstSolved: boolean;
  passwordErrorSecondSolved: boolean;
  passwordErrorThirdSolved: boolean;
  passwordPopUp: boolean;
  // translate: (id: string) => string;
}

const PasswordStrengthTipComponent: React.FC<PasswordStrengthTipProps> = ({
  passwordErrorFirstSolved,
  passwordErrorSecondSolved,
  passwordErrorThirdSolved,
  // translate,
}) =>
  !(
    passwordErrorFirstSolved &&
    passwordErrorSecondSolved &&
    passwordErrorThirdSolved
  ) ? (
    <div className={"password-strength-tip"}>
      <span className="password-strength-tip-title">
        {/* {translate("password.strength.tip.influence")} */}
        What influence on password strength
      </span>
      {!passwordErrorFirstSolved && (
        <span className="password-strength-tip-text">
          {/* {translate("password.strength.tip.number.characters")} */}
          At least 8 characters
        </span>
      )}
      {!passwordErrorSecondSolved && (
        <span className="password-strength-tip-text">
          {/* {translate("password.strength.tip.letter")} */}
          At least One upper case and lower case letter
        </span>
      )}
      {!passwordErrorThirdSolved && (
        <span className="password-strength-tip-text">
          {/* {translate("password.strength.tip.digit")} */}
          At least One digit
        </span>
      )}
    </div>
  ) : null;

export const PasswordStrengthTip = React.memo(PasswordStrengthTipComponent);
