import React from "react";
import "./privacy.scss";

// import { Link } from "react-router-dom";
// import { appTitle } from "../../api";

export const PrivacyPage = () => {
  return (
    <>
      <div className="container privacy-pg_wrapper">
        <div className="privacy-pg">
          <h2 className="text-center">Privacy Policy</h2> 

        </div>
      </div>
    </>
  );
};
