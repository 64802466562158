import * as React from "react";

interface SettingsIconProps {
  className?: string;
}

export const SettingsIcon: React.FC<SettingsIconProps> = (props: SettingsIconProps) => {
  return (


    <>

      <svg className={props.className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.35285 2.08139C8.02658 -0.6938 11.9734 -0.693795 12.6471 2.08139C12.7628 2.5579 13.3088 2.78402 13.7275 2.52888C16.1662 1.04293 18.9571 3.83376 17.4711 6.27251C17.216 6.69125 17.4421 7.23717 17.9186 7.35285C20.6938 8.02658 20.6938 11.9734 17.9186 12.6471C17.4421 12.7628 17.216 13.3088 17.4711 13.7275C18.9571 16.1662 16.1662 18.9571 13.7275 17.4711C13.3088 17.216 12.7628 17.4421 12.6471 17.9186C11.9734 20.6938 8.02658 20.6938 7.35285 17.9186C7.23717 17.4421 6.69125 17.216 6.27252 17.4711C3.83376 18.9571 1.04293 16.1662 2.52889 13.7275C2.78402 13.3088 2.5579 12.7628 2.08139 12.6471C-0.693795 11.9734 -0.6938 8.02658 2.08139 7.35285C2.5579 7.23717 2.78402 6.69125 2.52888 6.27251C1.04293 3.83376 3.83376 1.04293 6.27252 2.52888C6.69125 2.78402 7.23717 2.55789 7.35285 2.08139ZM10.7036 2.55323C10.5245 1.81559 9.47547 1.81559 9.2964 2.55322C8.86118 4.34596 6.80726 5.19673 5.23186 4.23681C4.58365 3.84185 3.84185 4.58365 4.23681 5.23185C5.19673 6.80726 4.34596 8.86118 2.55322 9.2964C1.81559 9.47547 1.81559 10.5245 2.55323 10.7036C4.34596 11.1388 5.19673 13.1927 4.23682 14.7681C3.84186 15.4164 4.58365 16.1581 5.23186 15.7632C6.80726 14.8033 8.86118 15.654 9.2964 17.4468C9.47547 18.1844 10.5245 18.1844 10.7036 17.4468C11.1388 15.654 13.1927 14.8033 14.7681 15.7632C15.4164 16.1581 16.1581 15.4164 15.7632 14.7681C14.8033 13.1927 15.654 11.1388 17.4468 10.7036C18.1844 10.5245 18.1844 9.47547 17.4468 9.2964C15.654 8.86118 14.8033 6.80726 15.7632 5.23185C16.1581 4.58364 15.4164 3.84185 14.7681 4.23682C13.1927 5.19673 11.1388 4.34596 10.7036 2.55323ZM10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8ZM6 10C6 7.79086 7.79086 6 10 6C12.2091 6 14 7.79086 14 10C14 12.2091 12.2091 14 10 14C7.79086 14 6 12.2091 6 10Z" fill="var(--icon)" />
      </svg>


    </>
  );
};
