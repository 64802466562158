// tslint:disable-next-line
import { takeLatest } from 'redux-saga/effects';
import {
    EMAIL_VERIFICATION_FETCH, EMAIL_VERIFICATION_RESEND_FETCH,
} from '../constants';
import { emailVerificationSaga } from './emailVerificationSaga';
import { emailVerificationResendSaga } from './emailVerificationResendSaga';

export function* rootEmailVerificationSaga() {
    yield takeLatest(EMAIL_VERIFICATION_FETCH, emailVerificationSaga);
    yield takeLatest(EMAIL_VERIFICATION_RESEND_FETCH, emailVerificationResendSaga);
}
