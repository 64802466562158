import { CommonError } from '../../types';
import { CouponsCheckAction } from './actions';
import { COUPONS_CHECKBOX, COUPONS_CHECK_CLEAR, COUPONS_CHECK_VALID_DATA, COUPONS_CHECK_VALID_ERROR, COUPONS_CHECK_VALID_FETCH } from './constants';
import { CouponCheck } from './types';

export interface CouponsCheckState {
  current: CouponCheck;
  loading: boolean;
  isValid: boolean;
  error?: CommonError;
  isCouponCode: boolean;
}

export const initialCouponsValidCheckState: CouponsCheckState = {

  current: {
    swap_id: '',
    swap_pair: '',
    discount_code: '',
    discount_percent: 0,
    start_time: '',
    end_time: '',
    status: '',
  },
  loading: false,
  isValid: false,
  isCouponCode: false,

};

export const couponsCheckReducer = (state = initialCouponsValidCheckState, action: CouponsCheckAction) => {
  switch (action.type) {
    case COUPONS_CHECK_VALID_FETCH:
      return {
        ...state,
        loading: true,
        isValid: false,
      };
    case COUPONS_CHECK_VALID_DATA:
      return {
        ...state,
        loading: false,
        isValid: true,
        current: action.payload,
      };
    case COUPONS_CHECK_VALID_ERROR:
      return {
        ...state,
        loading: false,
        isValid: false, 
      };
    case COUPONS_CHECK_CLEAR:
      return {
        ...state,
        loading: false,
        isValid: false,
        current: initialCouponsValidCheckState.current,
      };
    case COUPONS_CHECKBOX:
      return {
        ...state,
        isCouponCode: action.payload,
      };
    default:
      return state;
  }
};



