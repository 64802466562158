import React, { useEffect, useState } from "react";
import YouTubePlayer from "react-youtube-player"
import "./watchyoutube.scss";
import {
  Badge, Button
  , ProgressBar
} from "../../components";
// import { truncateMiddle } from "../../helpers";
// import axios from "axios";
// import { TaskCard } from "../../components";

// type VideoView = {
//   id: number;
//   start_time: Date;
//   end_time: Date | null;
// };

// type Heartbeat = {
//   user_id: number;
//   last_heartbeat_time: Date;
// };

const currentTask = [
  {
    id: 1,
    video_id: 'j3MCD6bPsow',
    tumbnail: 'https://i3.ytimg.com/vi/rZMEInVMHeI/maxresdefault.jpg',
    title: "How to Make Headphone Audio",
    points: 15,
    available_task: '100',
    type: "watch",
    duration: "30"
  },
]



export const WatchYoutube = () => {

  const [videoUrl, setVideoUrl] = useState("");
  const [points, setPoints] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(100);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);



  useEffect(() => {
    setVideoUrl(currentTask[0].video_id)
    setPoints(currentTask[0].points)

    if (progress === 0) {
      if (timer) clearInterval(timer);
    }


  }, [progress, timer])

  const startTimer = () => {
    if (!timer) {
      const newTimer = setInterval(() => {
        setProgress(prevProgress => prevProgress - (100 / parseInt(currentTask[0].duration))); // decrement by 3.33% every second for 30 seconds
      }, 1000);
      setTimer(newTimer);
    }
  };
  const pauseTimer = () => {
    if (timer) {
      clearInterval(timer);
      setTimer(null);
    }
  };

  const handlePlay = (): void => {
    console.log('Video is playing');
    setIsPlaying(true);

    // // return true;
    // startTimer()
  };

  const handlePause = () => {
    // setIsPlaying(false);
    // pauseTimer()
  };

  // const handleChangeVideoUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setVideoUrl(e.target.value);
  // };


  return (
    <>
      <div className="container cx-center watch-youtube bg-trans ">
        <div className="col">

          <h3>Watch Youtube Videos</h3>
          <div className="watch-youtube-wrapper">
            {/*  */}

            <div className="watch-youtube__main">

              <div className="watch-youtube-card__wrapper main-watch">
                <div className="watch-youtube-card__thumbnail">
                  {/* <img src={currentTask[0].tumbnail} alt="Tumbnail" /> */}
                  <YouTubePlayer
                    videoId={videoUrl}
                  // onPlay={handlePlay} 
                  // onPause={handlePause}
                  // onChangeEvent={handlePlay}
                  />

                </div>
                <div className="watch-youtube-card__footer">
                  <div className="watch-youtube-card__footer__details">
                    <span>{currentTask[0].title} </span>
                    <span className="timer">5 Sec</span>
                    <ProgressBar fill={progress} type="primary" />


                  </div>
                  <div className="watch-youtube-card__footer__points">
                    <span>Points: <Badge type="success" lable={currentTask[0].points.toString()} /></span>
                    <Button type="primary" lable="Claim" disabled size="small" className="fit" />
                  </div>

                </div>
              </div>

              <div className="watch-youtube__items__wrapper col">

                <div className="watch-youtube-card__wrapper">
                  <div className="watch-youtube-card__thumbnail">
                    <img src={currentTask[0].tumbnail} alt="Tumbnail" />
                  </div>
                  <div className="watch-youtube-card__footer">
                    <div className="watch-youtube-card__footer__details ">
                      <span className="txs">{currentTask[0].title} </span>

                    </div>
                    <div className="watch-youtube-card__footer__points">
                      <span>Points: <Badge type="success" lable={currentTask[0].points.toString()} /></span>
                    </div>

                  </div>
                </div>


                <div className="watch-youtube-card__wrapper">
                  <div className="watch-youtube-card__thumbnail">
                    <img src={currentTask[0].tumbnail} alt="Tumbnail" />
                  </div>
                  <div className="watch-youtube-card__footer">
                    <div className="watch-youtube-card__footer__details">
                      <span className="txs">{currentTask[0].title} </span>

                    </div>
                    <div className="watch-youtube-card__footer__points">
                      <span>Points: <Badge type="success" lable={currentTask[0].points.toString()} /></span>
                    </div>

                  </div>
                </div>

              </div>
            </div>
            {/* seperate section */}
            <div className="watch-youtube__items__wrapper yt ">

              <div className="watch-youtube-card__wrapper">
                <div className="watch-youtube-card__thumbnail">
                  <img src={currentTask[0].tumbnail} alt="Tumbnail" />
                </div>
                <div className="watch-youtube-card__footer">
                  <div className="watch-youtube-card__footer__details">
                    <span className="txs">{currentTask[0].title} </span>

                  </div>
                  <div className="watch-youtube-card__footer__points">
                    <span>Points: <Badge type="success" lable={currentTask[0].points.toString()} /></span>
                  </div>

                </div>
              </div>
              <div className="watch-youtube-card__wrapper">
                <div className="watch-youtube-card__thumbnail">
                  <img src={currentTask[0].tumbnail} alt="Tumbnail" />
                </div>
                <div className="watch-youtube-card__footer">
                  <div className="watch-youtube-card__footer__details">
                    <span className="txs">{currentTask[0].title} </span>

                  </div>
                  <div className="watch-youtube-card__footer__points">
                    <span>Points: <Badge type="success" lable={currentTask[0].points.toString()} /></span>
                  </div>

                </div>
              </div>
              <div className="watch-youtube-card__wrapper">
                <div className="watch-youtube-card__thumbnail">
                  <img src={currentTask[0].tumbnail} alt="Tumbnail" />
                </div>
                <div className="watch-youtube-card__footer">
                  <div className="watch-youtube-card__footer__details">
                    <span className="txs">{currentTask[0].title} </span>

                  </div>
                  <div className="watch-youtube-card__footer__points">
                    <span>Points: <Badge type="success" lable={currentTask[0].points.toString()} /></span>
                  </div>

                </div>
              </div>



            </div>






          </div>
        </div>
      </div>
    </>
  );
};
