import React, { useEffect, useState } from "react";
import "./withdrawhistory.scss";

import {

  Pagination,
  Status,
  Table,
  // ,Button, Radio
} from "../../components";
// import { Link } from "react-router-dom";
// import { MenuIcon } from "../../assets/icons";

import { capitalize, localeDate, truncateMiddle } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import {

  Currency,
  currenciesFetch,
  // HistoryList, Transaction,
  fetchHistory, resetHistory, selectCurrencies, selectCurrentPage, selectHistory, selectHistoryTotal,
} from "../../modules";
import { Statuses } from "../../api";

export const WithdrawHistory = () => {
  const dispatch = useDispatch()

  const [paginationLimit, setPaginationLimit] = React.useState(10);

  const histories = useSelector(selectHistory)
  const type = 'withdraws'
  const currentPage = useSelector(selectCurrentPage);
  const withdrawsTotal = useSelector(selectHistoryTotal);
  // const [initiaFetch, setInitiaFetch] = useState(false)

  const currencies = useSelector(selectCurrencies);

  useEffect(() => {
    if (!currencies.length){
      dispatch(currenciesFetch())
    }

 
      dispatch(fetchHistory({
        type: type,
        limit: paginationLimit,
        page: 0,
      }));
    
    return () => {
      // cleanup
      dispatch(resetHistory());
    };
  }, [dispatch,paginationLimit, type]);




  const getExplorerLink = (currencies: Currency[], currency: string, txid: string) => {
    const currencyData = currencies.find((c) => c.id === currency);
    if (!currencyData) return "";
    return currencyData.explorer_transaction.replace("#{txid}", txid);
  }

  const Column = [
    { title: "Date", style: { minWidth: 170 } },
    // { title: "Type" },
    { title: "Amount" },
    { title: "Currency" },
    { title: "Fee" },
    { title: "Address" },
    { title: "Conf" },
    { title: "TxID" },
    { title: "Status" },
  ];

  const renderCells = (histories) =>
    
    histories.map((history, index) => {
      const { created_at,
        // address,
        amount, state, confirmations, blockchain_txid, fee, currency, rid } = history;
      return (
        <tr key={index}>
          <td>{localeDate(created_at, 'fullDate')}</td>
          <td>{parseFloat(amount).toFixed(8)}</td>
          <td>{currency?.toUpperCase()}</td>
          <td>{fee}</td>
          <td>{rid}</td>

          <td>{confirmations}</td>
          <td><a href={getExplorerLink(currencies, currency, blockchain_txid)}>{truncateMiddle(blockchain_txid, 10)}</a></td>
          <td>
            <Status type={Statuses[state]} label={state && capitalize(state)} />
          </td>
        </tr>
      );
    });


  const nextPageExists = (withdrawsTotal / paginationLimit) > currentPage;
  const firstElemIndex = (currentPage - 1) * paginationLimit + 1;
  const lastElemIndex = (currentPage - 1) * paginationLimit + histories.length;


  const onClickNextPage = () => {
    dispatch(fetchHistory({ page: (currentPage - 1) + 1, limit: paginationLimit, type: type }));
  };

  const onClickPrevPage = () => {
    dispatch(fetchHistory({ page: (currentPage - 1) - 1, limit: paginationLimit, type: type }));

  };

  return (
    <>
      <div className="container cx-center history bg-trans">

        <div className="row">
          <div className="history">
            <h3>Withdraw Histories</h3>
            <Table column={Column} tableRows={renderCells(histories)} />

            {histories.length > 0 &&
              <Pagination
                firstElemIndex={firstElemIndex}
                total={withdrawsTotal}
                lastElemIndex={lastElemIndex}
                page={currentPage}
                nextPageExists={nextPageExists}
                onClickPrevPage={onClickPrevPage}
                onClickNextPage={onClickNextPage}
              />}
          </div>
        </div>

      </div>
    </>
  );
};
