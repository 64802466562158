import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import { sagaMiddleware, store } from "./store";
import { rootSaga } from "./modules";

import { WrappedComponentProps } from "react-intl";

sagaMiddleware.run(rootSaga);
export type IntlProps = WrappedComponentProps;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
