import React, { useState } from "react";
import "./accountmenu.scss";
import { Link } from "react-router-dom";

import { UserIcon } from "@heroicons/react/24/solid";
import {
  Squares2X2Icon,
  Cog6ToothIcon,
  WalletIcon,
  PowerIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { logoutFetch, selectUserInfo } from "../../modules";
export const AccountMenu = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserInfo);

  const uLogOut = () => {
    dispatch(logoutFetch());
  };

  const [toggle, setToggle] = useState(false);

  return (
    <>
      <div className="account-menu_wrapper">
        <div className="account-menu_content">
          <div className="account-menu_icon" onClick={() => setToggle(!toggle)}>
            <UserIcon className="i32" />
          </div>

          {toggle ? (
            <div
              className="account-menu_dropdown"
              onMouseLeave={() => setToggle(false)}
            >
              {user.role === "superadmin" && (
                <div className="account-menu_dropdown_item">
                  <a href="/backend">
                    <Squares2X2Icon className="i24" />
                    Admin
                  </a>
                </div>
              )}

              <div className="account-menu_dropdown_item">
                <Link to="/wallet">
                  <WalletIcon className="i24" />
                  Wallet
                </Link>
              </div>
              <div className="account-menu_dropdown_item">
                <Link to="/profile">
                  <Cog6ToothIcon className="i24" />
                  Settings
                </Link>
              </div>
              <div className="account-menu_dropdown_item logout">
                {/* <Link to="/admin/dashboard"> */}
                <span onClick={uLogOut}>
                  <PowerIcon className="i24" />
                  Logout
                </span>
                {/* </Link> */}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
