import React from "react";
import "./panel.scss";

interface PanelProp {
  headerRightContent?: JSX.Element;
  panelContent?: JSX.Element;
  className?: String;
  title: String;
}
// MY API KEYS

export function Panel(props: PanelProp) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { title, className, panelContent, headerRightContent } = props;

  return (
    <>
      <div className={"panel " + (className ? className : "")}>
        <div className="panel_header">
          {title}
          {headerRightContent ? (
            <div className="right_content">{headerRightContent}</div>
          ) : null}
        </div>

        <div className="panel_content">{panelContent}</div>
      </div>
    </>
  );
}
