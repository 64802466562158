import React, { useEffect, useState } from "react";
import "./exchange.scss";
import { Input, InputSelect } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { couponCheckClear, couponCheckbox, couponsCheckValidFetch, selectCurrentCoupon, selectIsCouponCode, selectSwaps, selectSwapsLoading, selectWallets, swapsFetch, swapsSwapFetch, walletsFetch } from "../../modules";
import { Oval } from "react-loader-spinner";

export const Exchange = () => {
  const dispatch = useDispatch();

  const initialSwappairs = [
    {
      swap_pair: 'trkywtrky',
      base_currency: 'trky',
      quote_currency: 'wtrky',
      price: '1'
    }
  ]

  const [tab, setTab] = useState("presale");

  const wallets = useSelector(selectWallets);
  const swaps = useSelector(selectSwaps)
  const loading = useSelector(selectSwapsLoading);
  const currentCoupon = useSelector(selectCurrentCoupon)
  const isCouponCode = useSelector(selectIsCouponCode)

  const [currentSwapPair, setCurrentSwapPair] = useState(initialSwappairs[0])
  const [swap_amount, setSwapAmount] = useState('')
  const [swap_total, setSwapTotal] = useState(0)

  const [isDefaultSwap, setIsDefaultSwap] = useState(false)
  const [isInitialised, setIsInitialised] = useState(false)

  // const [isCouponCode, setIsCouponCode] = useState(false)
  const [couponCode, setCouponCode] = useState('')

  const initialSwapCurrencies = [{
    currency: 'trky',
    base: true
  },
  {
    currency: 'wtrky',
    base: false
  }]

  const [swap_direction, setSwapDirection] = useState(initialSwapCurrencies)

  const getBaseCurrencies = () => {
    return swap_direction.map((item) => item.currency)
  }
  const getBaseCurrency = () => {
    const baseCurrency = swap_direction.filter((item) => item.base === true)
    return baseCurrency.map((item) => item.currency)[0]
  }

  const getQouteCurrencies = () => {
    const qouteCurrency = swap_direction.filter((item) => item.base === false)
    return qouteCurrency.map((item) => item.currency)
  }
  const getQouteCurrency = () => {
    const qouteCurrency = swap_direction.filter((item) => item.base === false)
    return qouteCurrency.map((item) => item.currency)[0] || ""
  }


  const getSwapRate = () => {
    const baseCurrency = getBaseCurrency()
    const qouteCurrency = getQouteCurrency()

    if (currentSwapPair.base_currency === baseCurrency && currentSwapPair.quote_currency === qouteCurrency) {
      return parseFloat(currentSwapPair.price)
    } else if (getBaseCurrencies().includes(baseCurrency) && getQouteCurrencies().includes(qouteCurrency)) {
      return 1 / parseFloat(currentSwapPair.price)
    }
    return 0
  }

  const getIconUrlByCurrencyCode = (code: string) => {
    const wallet = wallets.find((item) => item.currency.toLowerCase() === code.toLowerCase());
    if (wallet) {
      return wallet.iconUrl;
    }
    return '';
  };

  const walletBalanceByCurrencyCode = (currency: string) => {
    const wallet = wallets.find((item) => item.currency.toLowerCase() === currency.toLowerCase());
    if (wallet) {
      return wallet.balance;
    }
    return "0";
  };

  const handleSwapFromCurrency = (name: string) => {
    const newSwapDirection = swap_direction.map((item) => {
      if (item.currency === name) {
        return { ...item, base: true }
      }
      else {
        return { ...item, base: false }
      }
    })
    setSwapDirection(newSwapDirection)
  }

  const handleChangeSwapAmount = (value: string) => {
    setSwapAmount(value)

    let total = parseFloat(value) * (getSwapRate() || 0)
    if (isCouponCode && currentCoupon.discount_percent > 0) {
      total = total + (total * currentCoupon.discount_percent / 100)
    }

    setSwapTotal(total)
  }

  const handleCouponCheck = (value: boolean) => {
    dispatch(couponCheckbox(!value))

    //if unchecked, clear coupon code
    if (value) {
      setCouponCode('')
      dispatch(couponCheckClear())
      //recalculate total amount
      let total = parseFloat(swap_amount) * (getSwapRate() || 0)
      setSwapTotal(total)

    }
  }

  const handleChangeCouponCode = (value: string) => {
    setCouponCode(value)
    //if length is 10, call api to validate coupon code
    if (value.length == 10) {
      dispatch(couponsCheckValidFetch({ discount_code: value, swap_pair: currentSwapPair.swap_pair }))
      setSwapAmount('')
      setSwapTotal(0)
    } else {
      dispatch(couponCheckClear())
    }
  }

  const handleSwap = () => {
    if (tab === 'presale') {
      const payload = {
        swap_pair: currentSwapPair.swap_pair,
        base_amount: parseFloat(swap_amount),
        base_currency: getBaseCurrency(),
        quote_currency: getQouteCurrency(),
        discount_code: couponCode,
        type: 'presale',
      }

      //if key is not present, remove it from payload
      Object.keys(payload).forEach((key) => (payload[key] === "") && delete payload[key]);

      dispatch(swapsSwapFetch(payload))
    }

  }

  useEffect(() => {
    if (!wallets.length && !isInitialised) {
      dispatch(walletsFetch());
    }
    if (!swaps.length && !isInitialised) {
      dispatch(swapsFetch());
    }

    if (swaps.length && wallets.length && !isDefaultSwap) {
      //filter where swap_type is swap
      const filteredSwaps = swaps.filter((item) => item.swap_type === tab)
      setCurrentSwapPair(filteredSwaps[0])
      setSwapDirection([{ currency: filteredSwaps[0].base_currency, base: true }, { currency: filteredSwaps[0].quote_currency, base: false }])
      setIsDefaultSwap(true)
    }

    setIsInitialised(true)

    // Fetch swaps every 3 seconds
    // const interval = setInterval(() => {
    //   dispatch(swapsFetch());
    // }, 3000);

    // return () => {
    //   // clearInterval(interval); 
    // };



  }, [wallets, swaps, tab])

  const handleChangTab = (name: string) => {
    if (name)
      setTab(name);

    const filteredSwaps = swaps.filter((item) => item.swap_type === name)
    setCurrentSwapPair(filteredSwaps[0])
    setSwapDirection([{ currency: filteredSwaps[0].base_currency, base: true }, { currency: filteredSwaps[0].quote_currency, base: false }])
  };



  // const renderSwap = () => {
  //   const baseCurrency = getBaseCurrency().toLocaleUpperCase()
  //   const qouteCurrency = getQouteCurrency().toLocaleUpperCase()
  //   const baseIcon = getIconUrlByCurrencyCode(baseCurrency)
  //   const qouteIcon = getIconUrlByCurrencyCode(qouteCurrency)

  //   if(loading){
  //     return(
  //       <div className="row swap_wrapper">
  //       <div className="loading">
  //       <Oval
  //             height="64"
  //             width="64"
  //             color="green"
  //             // secondaryColor="yellow"
  //           />
  //       </div>      
  //     </div>
  //     ) 
  //   }   


  //   if (!baseCurrency || !qouteCurrency || !wallets) {
  //     return <>
  //       <div className="row swap_wrapper">
  //         <h2 className="empty-data">NO DATA</h2>
  //       </div>
  //     </>
  //   }

  //   return (
  //     <>
  //       <div className="row swap_wrapper">
  //         <div className="swap_ccny_balance"></div>
  //         {qouteCurrency && baseCurrency && <div className="swap_rate"> 1 {baseCurrency} = {getSwapRate().toFixed(8)} {qouteCurrency}</div>}
  //         {qouteCurrency && baseCurrency && <div className="swap_user_balance">Balance: {parseFloat(walletBalanceByCurrencyCode(baseCurrency)).toFixed(8)} {baseCurrency}</div>}
  //         <label htmlFor="swap_from">From</label>
  //         <InputSelect
  //           leftAddon={<img className="input-logo" src={baseIcon}></img>}
  //           handleChangeInput={(value) => handleSwapFromCurrency(value)}
  //           selections={getBaseCurrencies()}
  //           value={baseCurrency}
  //           id="swap_from" />

  //         <label htmlFor="swap_to">To</label>
  //         <InputSelect disabled
  //           leftAddon={<img className="input-logo" src={qouteIcon}></img>}
  //           selections={getQouteCurrencies()}
  //           value={qouteCurrency.toLocaleUpperCase()}
  //           id="swap_to" />

  //         <label htmlFor="swap_amount">Amount</label>
  //         <Input
  //           value={swap_amount.toString()}
  //           type="number"
  //           id="swap_to"
  //           handleChangeInput={handleChangeSwapAmount}
  //           rightAddon={baseCurrency}
  //           placeholder={`Enter Amount `} />

  //         <label htmlFor="swap_amount">Total</label>
  //         <Input
  //           value={swap_total.toFixed(8)}
  //           type="number"
  //           id="swap_to" disabled
  //           placeholder={""}
  //           rightAddon={qouteCurrency} />

  //         <button onClick={handleSwap} disabled={!swap_amount || !currentSwapPair.swap_pair} className="btn btn-primary">Swap</button>
  //       </div>
  //     </>

  //   )
  // }

  const renderPresale = () => {
    const baseCurrency = getBaseCurrency().toLocaleUpperCase()
    const qouteCurrency = getQouteCurrency().toLocaleUpperCase()
    const baseIcon = getIconUrlByCurrencyCode(baseCurrency)
    const qouteIcon = getIconUrlByCurrencyCode(qouteCurrency)

    if (loading) {
      return (
        <div className="row swap_wrapper">
          <div className="loading">
            <Oval
              height="64"
              width="64"
              color="green"
            // secondaryColor="yellow"
            />
          </div>
        </div>
      )
    }


    if (!baseCurrency || !qouteCurrency || !wallets) {
      return <>
        <div className="row swap_wrapper">
          <h2 className="empty-data">NO DATA</h2>
        </div>
      </>
    }

    return (
      <>
        <div className="row swap_wrapper"><span className="badge badge-warning" style={{ padding: '8px 8px' }}>Presale</span>
          <div className="swap_ccny_balance"></div>
          {qouteCurrency && baseCurrency && <div className="swap_rate"> 1 {baseCurrency} = {getSwapRate().toFixed(8)} {qouteCurrency}</div>}
          {qouteCurrency && baseCurrency && <div className="swap_user_balance">Balance: {parseFloat(walletBalanceByCurrencyCode(baseCurrency)).toFixed(8)} {baseCurrency}</div>}

          <div className="row space-between"><label htmlFor="have_coupon_code" className="checkbox  ">
            <input type="checkbox"
              name="have_coupon_code" id="have_coupon_code"
              checked={isCouponCode}
              onChange={() => handleCouponCheck(isCouponCode)}
            /><i className="input-helper"></i>I have Coupon Code</label>
            {currentCoupon.discount_percent && <span>discount: {currentCoupon.discount_percent}%  </span>}
          </div>


          {isCouponCode && <>
            <label htmlFor="coupon_code">Coupon Code</label>
            <Input
              value={couponCode}
              handleChangeInput={handleChangeCouponCode}
              type="text"
              id="coupon_code"
              placeholder={`Enter Coupon Code `}
              error={currentCoupon.discount_code == '' && isCouponCode && couponCode ? true : false}
              errorMessage="Invalid Coupon"

            /></>}



          <label htmlFor="swap_from">From</label>
          <InputSelect
            leftAddon={<img className="input-logo" src={baseIcon}></img>}
            handleChangeInput={(value) => handleSwapFromCurrency(value)}
            selections={getBaseCurrencies()}
            value={baseCurrency}
            id="swap_from" />

          <label htmlFor="swap_to">To</label>
          <InputSelect disabled
            leftAddon={<img className="input-logo" src={qouteIcon}></img>}
            selections={getQouteCurrencies()}
            value={qouteCurrency.toLocaleUpperCase()}
            id="swap_to" />

          <label htmlFor="swap_amount">Amount</label>
          <Input
            value={swap_amount.toString()}
            type="number"
            id="swap_to"
            handleChangeInput={handleChangeSwapAmount}
            rightAddon={baseCurrency}
            placeholder={`Enter Amount `} />


          <label htmlFor="swap_amount">Total</label>
          <Input
            value={swap_total.toFixed(8)}
            type="number"
            id="swap_to" disabled
            placeholder={""}
            rightAddon={qouteCurrency} />

          <button onClick={handleSwap} disabled={!swap_amount || !currentSwapPair.swap_pair} className="btn btn-primary">Swap</button>
        </div>
      </>

    )
  }

  return (
    <>
      <div className=" container cx-center exchange bg-trans ">
        {/* <h3>Exchange</h3> */}

        {/* Tab */}
        <div className="exchange-tab_wrapper">
          <div
            className={`exchange-tab ${tab === "presale" ? "active" : ""}`}
            onClick={() => handleChangTab('presale')}
          >
            Presale
          </div>

          {/* <div
            className={`exchange-tab ${tab === "swap" ? "active" : ""}`}
            onClick={() => handleChangTab('swap')}
          >
            Swap
          </div> */}

        </div>
        {/* Tab Ends */}



        {/* {tab === 'swap' &&   renderSwap() }  */}

        {tab === 'presale' && renderPresale()}


      </div>
    </>
  );
};
