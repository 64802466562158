import { CommonError } from "../../types";
import { SwapHistory } from "../history";
import {
  SWAPS_DATA,
  SWAPS_ERROR,
  SWAPS_FETCH,
  SWAPS_SWAP_DATA,
  SWAPS_SWAP_ERROR,
  SWAPS_SWAP_FETCH,
} from "./constants";
import { Swap } from "./types";

export interface SwapsFetch {
  type: typeof SWAPS_FETCH;
}

export interface SwapsData {
  type: typeof SWAPS_DATA;
  payload: Swap[];
}

export interface SwapsError {
  type: typeof SWAPS_ERROR;
  error: CommonError;
}

export interface SwapsSwapFetch {
  type: typeof SWAPS_SWAP_FETCH;
  payload: {
    base_amount: number;
    swap_pair: string;
    type: string;
    base_currency: string;
    quote_currency: string;

  };
}
 
export interface SwapsSwapData {
  type: typeof SWAPS_SWAP_DATA;
  payload: SwapHistory;  
}

export interface SwapsSwapError {
  type: typeof SWAPS_SWAP_ERROR;
  error: CommonError;
}
  

export type SwapsAction = SwapsData
 | SwapsError 
 | SwapsFetch
 | SwapsSwapData
 | SwapsSwapError
 | SwapsSwapFetch;

export const swapsFetch = (): SwapsFetch => ({
  type: SWAPS_FETCH,
});

export const swapsData = (payload: Swap[]): SwapsData => ({
  type: SWAPS_DATA,
  payload,
});

export const swapsError = (error: CommonError): SwapsError => ({
  type: SWAPS_ERROR,
  error,
});

export const swapsSwapFetch = (payload: SwapsSwapFetch['payload']): SwapsSwapFetch => ({
  type: SWAPS_SWAP_FETCH,
  payload,
});

export const swapsSwapData = (payload: SwapHistory): SwapsSwapData => ({
  type: SWAPS_SWAP_DATA,
  payload,
});

export const swapsSwapError = (error: CommonError): SwapsSwapError => ({
  type: SWAPS_SWAP_ERROR,
  error,
});