import React, { Component } from "react";
import "./progressbar.scss";
// import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";

type Props = {
  size?: string;
  type?: "primary";
  label?: string;
  // name: string;
  className?: string;
  fill?: number;
  handleChangeInput?: (value: string) => void;
};

interface onChangeEvent {
  target: {
    value: string;
  };
}
export class ProgressBar extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  private handleChangeValue(e: onChangeEvent) {
    this.props.handleChangeInput &&
      this.props.handleChangeInput(e.target.value);
  }
  render() {
    const { size, type, label, fill, className } = this.props;

    return (
      <>
        <div
          className={`progress-bar-wrapper    ${size ? size : ""} ${className ? className : ""
            } `}
        >
          <div className={`progress-bar ${type}`} style={{ width: `${fill}%` }}></div>

        </div>
      </>
    );
  }
}
