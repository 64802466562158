import React, { Component } from "react";
import "./checkbox.scss";
// import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";

type Props = {
  size?: string;
  type?: "primary";
  label?: string;
  name: string;
  className?: string;
  checked?: boolean;
  clickCheckBox?: (e: any) => void;
  handleChangeInput?: (value: string) => void;
};

interface onChangeEvent {
  target: {
    value: string;
  };
}

export class Checkbox extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  private handleChangeValue(e: onChangeEvent) {
    this.props.handleChangeInput &&
      this.props.handleChangeInput(e.target.value);
  }
  render() {
    const { size, label, name, className, checked, clickCheckBox } = this.props;

    return (
      <>
        <label
          htmlFor={name}
          onClick={clickCheckBox}
          className={`checkbox ${className ? className : ""} ${size ? size : ""
            }`}
        >
          <input
            type="checkbox"
            checked={checked}
            name={name}
            id={name}
            onChange={(e) => this.handleChangeValue(e)}
          />
          <i className="input-helper"></i>
          {label ? label : ""}
        </label>
      </>
    );
  }
}
