import React, { useEffect, useState } from "react";
import "./swaphistory.scss";

import {
  Pagination,
  Status,
  Table,
  // ,Button, Radio
} from "../../components";
// import { Link } from "react-router-dom";
// import { MenuIcon } from "../../assets/icons";

import { capitalize, localeDate } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHistory,
  resetHistory,
  selectCurrentPage,
  selectHistory,
  selectHistoryTotal,
} from "../../modules";
import { Statuses } from "../../api";

export const SwapHistory = () => {
  const histories = useSelector(selectHistory);
  const type = "swaps";
  const dispatch = useDispatch();
  const [paginationLimit, setPaginationLimit] = useState(10);
  const currentPage = useSelector(selectCurrentPage);
  const depositsTotal = useSelector(selectHistoryTotal);

  useEffect(() => {
    //
    dispatch(
      fetchHistory({
        type: type,
        limit: paginationLimit,
        page: 0,
      })
    );

    return () => {
      // cleanup
      dispatch(resetHistory());
    };
    // eslint-disable-next-line
  }, [dispatch, paginationLimit, type]);

  const Column = [
    { title: "Date", style: { minWidth: 170 } },
    { title: "Amount" },
    { title: "Total" },
    { title: "Type" },
    { title: "Status" },
  ];

  const renderCells = (histories) =>
    histories.map((history, index) => {
      const {
        created_at,
        swap_type,
        base_amount,
        base_currency,
        quote_amount,
        quote_currency,
        status,
      } = history;

      return (
        <tr key={index}>
          <td>{localeDate(created_at, "fullDate")}</td>
          <td>
            {parseFloat(base_amount).toFixed(8)} {base_currency?.toLocaleUpperCase()}
          </td>
          <td>
            {parseFloat(quote_amount).toFixed(8)} {quote_currency?.toLocaleUpperCase()}
          </td>
          <td>
            <span className={swap_type?.toLocaleLowerCase()}>
              {swap_type && capitalize(swap_type)}
            </span>
          </td>
          <td>
            <Status type={Statuses[status]} label={status && capitalize(status)} />
          </td>
        </tr>
      );
    });



  const nextPageExists = depositsTotal / paginationLimit > currentPage;
  const firstElemIndex = (currentPage - 1) * paginationLimit + 1;
  const lastElemIndex = (currentPage - 1) * paginationLimit + histories.length;

  const onClickNextPage = () => {
    dispatch(
      fetchHistory({
        page: currentPage - 1 + 1,
        limit: paginationLimit,
        type: type,
      })
    );
  };


  const onClickPrevPage = () => {
    dispatch(
      fetchHistory({
        page: currentPage - 1 - 1,
        limit: paginationLimit,
        type: type,
      })
    );
  };

  return (
    <>
      <div className="container cx-center history bg-trans">
        <div className="row">
          <div className="history">
            <h3>Swap Histories</h3>
            <Table column={Column} tableRows={renderCells(histories)} />

            {histories.length > 0 && (
              <Pagination
                firstElemIndex={firstElemIndex}
                total={depositsTotal}
                lastElemIndex={lastElemIndex}
                page={currentPage}
                nextPageExists={nextPageExists}
                onClickPrevPage={onClickPrevPage}
                onClickNextPage={onClickNextPage}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
