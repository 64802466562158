import React from "react";

import "./task.scss";
import { TaskCard } from "../../components";
import { Link } from "react-router-dom";

const allTask = [

  {
    id: 1,
    title: 'Watch Video',
    platform: 'youtube',
    available_task: '0',
    path: '/task/youtube/watch'
  },
  {
    id: 2,
    title: 'Like Video',
    platform: 'youtube',
    available_task: '0',
    path: '/task/youtube/like'
  },
  {
    id: 3,
    title: 'Share Video',
    platform: 'youtube',
    available_task: '0',
    path: '/task/youtube/share'
  },

  // {
  //   id: 4,
  //   title: 'Like Video',
  //   platform: 'youtube',
  //   available_task: '0'
  // },
  {
    id: 5,
    title: 'Subscribe Channel',
    platform: 'youtube',
    available_task: '0',
    path: '/task/youtube/subscribe'
  },
  {
    id: 6,
    title: 'Join Group',
    platform: 'telegram',
    available_task: '0'
  },
  {
    id: 7,
    title: 'Join Channel',
    platform: 'telegram',
    available_task: '0'
  },
  {
    id: 8,
    title: 'Share Tweet',
    platform: 'twitter',
    available_task: '0'
  },
  {
    id: 9,
    title: 'Like Tweet',
    platform: 'twitter',
    available_task: '0'
  },
  {
    id: 10,
    title: 'Follow',
    platform: 'twitter',
    available_task: '0'
  },
  {
    id: 11,
    title: 'Like Post',
    platform: 'facebook',
    available_task: '0'
  },
  {
    id: 12,
    title: 'Share Post',
    platform: 'facebook',
    available_task: '0'
  },
  {
    id: 13,
    title: 'Follow Channel',
    platform: 'facebook',
    available_task: '0'
  },
  {
    id: 14,
    title: 'Share Post',
    platform: 'instagram',
    available_task: '0'
  },
  {
    id: 15,
    title: 'Like Post',
    platform: 'instagram',
    available_task: '0'
  },
  {
    id: 16,
    title: 'Follow Channel',
    platform: 'instagram',
    available_task: '0'
  },
  {
    id: 17,
    title: 'Share Post',
    platform: 'likee',
    available_task: '0'
  },
  {
    id: 18,
    title: 'Like Post',
    platform: 'likee',
    available_task: '0'
  },
  {
    id: 19,
    title: 'Follow Channel',
    platform: 'likee',
    available_task: '0'
  },
]
export const Task = () => {




  return (
    <>
      <div className=" container cx-center task bg-trans ">
        <div className="row task_wrapper">

          <h3>Task</h3>
          <div className="task-wrapper">
            {
              allTask.map(data => {
                const { id, title, platform, available_task, path } = data
                return (
                  <Link to={path ? path : "/task"}><TaskCard availableTask={available_task} key={id} platform={platform} title={title} /></Link>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  );
};
