import * as React from "react";

interface WithdrawIconProps {
  className?: string;
}

export const WithdrawIcon: React.FC<WithdrawIconProps> = (props: WithdrawIconProps) => {
  return (

    <>


      <svg className={props.className} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10 18C9.44772 18 9 17.5523 9 17L9 9.41421L7.70711 10.7071C7.31658 11.0976 6.68342 11.0976 6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289L9.29289 6.29289C9.68342 5.90237 10.3166 5.90237 10.7071 6.29289L13.7071 9.29289C14.0976 9.68342 14.0976 10.3166 13.7071 10.7071C13.3166 11.0976 12.6834 11.0976 12.2929 10.7071L11 9.41421L11 17C11 17.5523 10.5523 18 10 18ZM20 12C20 13.6569 18.6569 15 17 15L14 15C13.4477 15 13 14.5523 13 14C13 13.4477 13.4477 13 14 13L17 13C17.5523 13 18 12.5523 18 12L18 3C18 2.44772 17.5523 2 17 2L3 2C2.44771 2 2 2.44772 2 3L2 12C2 12.5523 2.44772 13 3 13L6 13C6.55228 13 7 13.4477 7 14C7 14.5523 6.55228 15 6 15L3 15C1.34315 15 -3.7969e-07 13.6569 -5.24537e-07 12L-1.31134e-06 3C-1.45619e-06 1.34315 1.34315 1.63103e-06 3 1.48619e-06L17 2.62268e-07C18.6569 1.17422e-07 20 1.34315 20 3L20 12Z" fill="var(--icon)" />
      </svg>


    </>
  );
};
