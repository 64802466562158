// tslint:disable-next-line
import { call, put, takeLatest } from 'redux-saga/effects';
import { API, RequestOptions } from '../../../../api';

import {
    TransactionsFetch,
    transactionsData,
    transactionsError,
} from '../actions';
import { TRANSACTIONS_FETCH } from '../constants';
import { alertPush } from '../../../public/alert';

const transactionsOptions: RequestOptions = {
    apiVersion: 'app',
};

export function* rootTransactionsSaga() {
    yield takeLatest(TRANSACTIONS_FETCH, transactionsFetchSaga);
}

export function* transactionsFetchSaga(action: TransactionsFetch) {
    try {
        const transactions = yield call(API.get(transactionsOptions), `/account/transactions?limit=${action.payload.limit}&currency=${action.payload.currency}`);
        // console.log("from Saga TransactionsFetch: ", transactions);
        yield put(transactionsData(transactions));
    } catch (error) {
        yield put(transactionsError());
        yield put(alertPush({ message: error.message, code: error.code, type: 'error' }));
    }
}