import * as React from "react";

interface DepositIconProps {
  className?: string;
}

export const DepositIcon: React.FC<DepositIconProps> = (props: DepositIconProps) => {
  return (

    <>

      <svg className={props.className} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10 0C10.5523 0 11 0.447715 11 1L11 8.58579L12.2929 7.29289C12.6834 6.90237 13.3166 6.90237 13.7071 7.29289C14.0976 7.68342 14.0976 8.31658 13.7071 8.70711L10.7071 11.7071C10.3166 12.0976 9.68342 12.0976 9.29289 11.7071L6.29289 8.70711C5.90237 8.31658 5.90237 7.68342 6.29289 7.29289C6.68342 6.90237 7.31658 6.90237 7.70711 7.29289L9 8.58579L9 1C9 0.447715 9.44771 5.96046e-08 10 0ZM0 6C0 4.34315 1.34315 3 3 3H6C6.55228 3 7 3.44772 7 4C7 4.55228 6.55228 5 6 5H3C2.44772 5 2 5.44772 2 6V15C2 15.5523 2.44772 16 3 16H17C17.5523 16 18 15.5523 18 15V6C18 5.44772 17.5523 5 17 5H14C13.4477 5 13 4.55228 13 4C13 3.44772 13.4477 3 14 3H17C18.6569 3 20 4.34315 20 6V15C20 16.6569 18.6569 18 17 18H3C1.34315 18 0 16.6569 0 15V6Z" fill="var(--icon)" />
      </svg>




    </>
  );
};
