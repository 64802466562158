import { call, put } from "redux-saga/effects";
// import { sendError } from '../../../';
import { API, RequestOptions } from "../../../../api";
// import { sendError } from '../../../public/errorHandler';
// import { changeLanguage } from '../../../public/i18n';
// import { userData } from '../../profile';
import {
  // signInData,
  // signInError,
  SignInFetch,
  signInData,
  signInError,
  signInRequire2FA,
  signUpRequireVerification,
  // signInRequire2FA, verificationSuccess,
  // signInRequire2FA,
  // signUpRequireVerification,
} from "../actions";
import { alertPush } from "../../../public/alert";
import { userData } from "../../profile";
import { sendError } from "../../../public/errorHandler";
import { changeLanguage } from "../../../public/i18n";

const sessionsConfig: RequestOptions = {
  apiVersion: "auth",
};
// const csrfConfig: RequestOptions = {
//   apiVersion: 'base',
// };

export function* signInSaga(action: SignInFetch) {
  try {
    // yield call(API.get(csrfConfig), '/sanctum/csrf-cookie');
    const user = yield call(
      API.post(sessionsConfig),
      "/identity/sessions",
      action.payload
    );

    if (user.state === "pending") {
      yield put(signUpRequireVerification({ requireVerification: true }));
    } else {
      if (user.data && JSON.parse(user.data).language) {
        yield put(changeLanguage(JSON.parse(user.data).language));
      }
      yield put(userData({ user }));

      localStorage.setItem("csrfToken", user.csrf_token);
      yield put(signInRequire2FA({ require2fa: user.otp }));
    }

    // yield put(userData({ user }));

    // yield put(
    //   alertPush({ type: "success", message: ["Successfully Authenticated"] })
    // );
    yield put(signInData());
  } catch (error) {
    if (
      error.code === 401 &&
      error.message.indexOf("identity.session.missing_otp") > -1
    ) {
      yield put(signInRequire2FA({ require2fa: true }));
      yield put(signInData());
    } else {
      yield put(
        sendError({
          error: error,
          processingType: "alert",
          extraOptions: {
            actionError: signInError,
          },
        })
      );
    }
  }
}
