import React, { Component } from "react";
import "./mytaskcard.scss";
import { Badge } from "../Badge";
import { Status } from "../Status";
import { capitalize } from "../../helpers";
import { Button } from "../Button";
// import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";

type Props = {
  title: string;
  platform: string;
  availableTask: string;
  status: string;
  id?: string;
  className?: string;
};

const Statuses = {
  enabled: "success",
  disabled: "danger",
};

export class MyTaskCard extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }


  render() {
    const { className, status, title, platform, availableTask } = this.props;

    return (
      <>
        <div className={`card my-task-card ${className ? className : ""}`}>
          <div className="my-task-card_icon flex">
            <img src={`/img/${platform}.svg`} alt={platform} />
            <Button type="secondary" lable="Edit" size="small" />
          </div>
          <div className="my-task-card_details">
            <div className="my-task-card_title">{title}</div>
            <div className="my-task-card_body">Remaining Task: <Badge type="success" lable={availableTask} /></div>
            <div className="my-task-card_status"><Status type={Statuses[status]} label={capitalize(status)} />  <Button type="primary" lable={status === 'enabled' ? "Disable" : "Enable"} size="small" /></div>
          </div>

        </div >

      </>
    );
  }
}
