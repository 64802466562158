import React from "react";
import "./App.scss";
import { Header } from "./containers";
import { Layout } from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import { Alerts } from "./containers";
import { IntlProvider } from "react-intl";
import { selectCurrentLanguage } from "./modules";
import { useSelector } from "react-redux";
import { languageMap } from "./translations";
import { Sidebar } from "./components/Sidebar";
// import { Footer } from "./containers/Footer";

function App() {
  const lang = useSelector(selectCurrentLanguage);
  const getTranslations = (lang: string) => {
    return languageMap[lang];
  };
  return (
    <div className="App">
      <IntlProvider locale="en" key={lang} messages={getTranslations(lang)}>
        <Router>
          <Alerts />
          <Header />
          <Layout />
          <Sidebar />
          {/* <Footer /> */}
        </Router>
      </IntlProvider>
    </div>
  );
}

export default App;

// locale="en" key={lang} messages={getTranslations(lang)}
