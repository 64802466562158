import React, { useState } from "react";
import "./forgotpassword.scss";
import { Button, Captcha, Input } from "../../components";
import { Link } from "react-router-dom";
import { EMAIL_REGEX } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPassword,
  resetCaptchaState,
  selectCaptchaResponse,
  selectConfigs,
  selectForgotPasswordError,
  selectForgotPasswordSuccess,
  selectGeetestCaptchaSuccess,
  selectRecaptchaSuccess,
} from "../../modules";
import { appTitle } from "../../api";

export const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");

  const error = useSelector(selectForgotPasswordError);
  const success = useSelector(selectForgotPasswordSuccess);
  const captcha_response = useSelector(selectCaptchaResponse);
  const reCaptchaSuccess = useSelector(selectRecaptchaSuccess);
  const geetestCaptchaSuccess = useSelector(selectGeetestCaptchaSuccess);
  const configs = useSelector(selectConfigs);

  const handleChangeEmail = (value) => {
    if (!EMAIL_REGEX.test(value)) {
      setErrorEmail("Invlid email address");
    } else {
      setErrorEmail("");
    }

    setEmail(value);
  };

  const renderCaptcha = () => {
    return <Captcha error={error} success={success} />;
  };

  const isButtonDisabled = (): boolean => {
    return configs.captcha_type !== "none" &&
      !reCaptchaSuccess &&
      !geetestCaptchaSuccess
      ? true
      : false;
  };

  const handleSubmit = () => {
    if (errorEmail === "") {
      switch (configs.captcha_type) {
        case "recaptcha":
        case "geetest":
          dispatch(forgotPassword({ email, captcha_response }));
          break;
        default:
          dispatch(forgotPassword({ email }));
          break;
      }
    }

    setErrorEmail("");
    dispatch(resetCaptchaState());
  };
  return (
    <>
      <div className="pg-login container">
        <Link to="/" className="l-link c-logo">
          <img src={"/img/mini-logo.png"} alt="Mini Logo" className="logo" />
          <span>{appTitle}</span>
        </Link>
        <div className="l-intro">
          <h2>Reset Password</h2>
        </div>
        <Input
          placeholder="Email"
          value={email}
          type="email"
          handleChangeInput={handleChangeEmail}
          error={errorEmail !== ""}
          errorMessage={errorEmail}
          className={errorEmail !== "" ? "error" : ""}
        />

        {configs.captcha_type !== "none" && renderCaptcha()}

        <Button
          type="primary"
          lable="Send Password Reset Link"
          className="x100"
          size="large"
          onClick={handleSubmit}
          disabled={isButtonDisabled()}
        />
        <span className="text-center">
          <Link to="/signin" className="l-link">
            Back
          </Link>
        </span>
      </div>
    </>
  );
};
