// import cr from 'classnames';
import React from "react";
// import { Button, Form } from 'react-bootstrap';

import { Link } from "react-router-dom";
import {
  Button,
  Checkbox,
  //  Checkbox,
  Input, PasswordStrengthMeter
} from "../";
// import { isUsernameEnabled } from '../../api';
import {
  EMAIL_REGEX,
  // eRROR_LONG_USERNAME,
  // eRROR_SHORT_USERNAME,
  PASSWORD_REGEX,
  USERNAME_REGEX,
  // uSERNAME_REGEX,
} from "../../helpers";
import { GeetestCaptchaResponse } from "../../modules";
import { appTitle, isUsernameEnabled } from "../../api";
// import { selectMobileDeviceState } from '../../modules/public/globalSettings';

export interface SignUpFormProps {
  isLoading?: boolean;
  onSignUp: () => void;
  onSignIn?: () => void;
  className?: string;
  image?: string;
  labelSignIn?: string;
  labelSignUp?: string;
  usernameLabel?: string;
  emailLabel?: string;
  passwordLabel?: string;
  confirmPasswordLabel?: string;
  referalCodeLabel?: string;
  termsMessage?: string;
  refId: string;
  password: string;
  username?: string;
  usernameError?: string;
  email: string;
  confirmPassword: string;
  handleChangeUsername?: (value: string) => void;
  handleChangeEmail: (value: string) => void;
  handleChangePassword: (value: string) => void;
  handleChangeConfirmPassword: (value: string) => void;
  handleChangeRefId: (value: string) => void;
  hasConfirmed: boolean;
  clickCheckBox?: (e: any) => void;
  validateForm: () => void;
  emailError: string;
  passwordError: string;
  confirmationError: string;
  handleFocusUsername?: () => void;
  handleFocusEmail: () => void;
  handleFocusPassword: () => void;
  handleFocusConfirmPassword: () => void;
  handleFocusRefId: () => void;
  confirmPasswordFocused: boolean;
  refIdFocused: boolean;
  usernameFocused?: boolean;
  emailFocused: boolean;
  passwordFocused: boolean;
  captchaType?: "recaptcha" | "geetest" | "none";
  renderCaptcha?: JSX.Element | null;
  reCaptchaSuccess?: boolean;
  geetestCaptchaSuccess?: boolean;
  captcha_response?: string | GeetestCaptchaResponse;
  currentPasswordEntropy: number;
  minPasswordEntropy: number;
  passwordErrorFirstSolved: boolean;
  passwordErrorSecondSolved: boolean;
  passwordErrorThirdSolved: boolean;
  passwordPopUp: boolean;
  myRef: any;
  passwordWrapper?: any;
  translate?: (id: string) => string;
}

const SignUpFormComponent: React.FC<SignUpFormProps> = ({
  username,
  email,
  confirmPassword,
  refId,
  // onSignIn,
  // image,
  isLoading,
  // labelSignIn,
  // labelSignUp,
  // usernameLabel,
  // emailLabel,
  // confirmPasswordLabel,
  passwordFocused,
  // referalCodeLabel,
  // termsMessage,
  captchaType,
  geetestCaptchaSuccess,
  hasConfirmed,
  reCaptchaSuccess,
  currentPasswordEntropy,
  passwordPopUp,
  password,
  // passwordLabel,
  emailError,
  passwordError,
  // translate,
  confirmationError,
  // usernameFocused,
  // emailFocused,
  passwordErrorFirstSolved,
  passwordErrorSecondSolved,
  // confirmPasswordFocused,
  handleChangePassword,
  passwordErrorThirdSolved,
  handleFocusPassword,
  minPasswordEntropy,
  // refIdFocused,
  validateForm,
  onSignUp,
  handleChangeUsername,
  // handleFocusUsername,
  handleChangeEmail,
  handleFocusEmail,
  handleChangeConfirmPassword,
  handleFocusConfirmPassword,
  handleChangeRefId,
  // handleFocusRefId,
  clickCheckBox,
  renderCaptcha,
  usernameError,

}) => {
  // const isMobileDevice = useSelector(selectMobileDeviceState);
  // const history = useHistory();
  // const { formatMessage } = useIntl();

  const disableButton = React.useMemo((): boolean => {
    if (
      !password ||
      !confirmPassword ||
      !email.match(EMAIL_REGEX) ||
      isLoading || 
      (isUsernameEnabled() && !username?.match(USERNAME_REGEX))
    ) {
      return true;
    }
    if (captchaType === "recaptcha" && !reCaptchaSuccess) {
      return true;
    }
    if (captchaType === "geetest" && !geetestCaptchaSuccess) {
      return true;
    }

    return false;
  }, [
    confirmPassword,
    username,
    captchaType,
    email,
    geetestCaptchaSuccess,
    isLoading,
    password,
    reCaptchaSuccess,
  ]);

  const handleSubmitForm = React.useCallback(() => {
    onSignUp();
  }, [onSignUp]);

  const isValidForm = React.useCallback(() => {
    const isEmailValid = email.match(EMAIL_REGEX);
    const isPasswordValid = password.match(PASSWORD_REGEX);
    const isConfirmPasswordValid = password === confirmPassword;

    return (
      email &&
      isEmailValid &&
      password &&
      isPasswordValid &&
      confirmPassword &&
      isConfirmPasswordValid
    );
  }, [confirmPassword, email, password]);

  const handleClick = React.useCallback(
    (e?: React.FormEvent<HTMLInputElement>) => {
      if (e) {
        e.preventDefault();
      }

      if (!isValidForm()) {
        validateForm();
      } else {
        handleSubmitForm();
      }
    },
    [handleSubmitForm, isValidForm, validateForm]
  );

  const handleEnterPress = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        event.preventDefault();

        handleClick();
      }
    },
    [handleClick]
  );

  // const renderUsernameError = (nick: string) => {
  //     return nick.length < 4 ? translate(ERROR_SHORT_USERNAME) : translate(ERROR_LONG_USERNAME);
  // };

  const renderPasswordInput = React.useCallback(() => {
    return (
      <>
        <Input
          type="password"
          id="password"
          placeholder="Password"
          handleChangeInput={handleChangePassword}
          value={password}
          handleFocusInput={handleFocusPassword}
        // inputError={''}
        />

        {password ? (
          <PasswordStrengthMeter
            minPasswordEntropy={minPasswordEntropy}
            currentPasswordEntropy={currentPasswordEntropy}
            passwordExist={password !== ""}
            passwordErrorFirstSolved={passwordErrorFirstSolved}
            passwordErrorSecondSolved={passwordErrorSecondSolved}
            passwordErrorThirdSolved={passwordErrorThirdSolved}
            passwordPopUp={passwordPopUp}
          // translate={translate}
          />
        ) : null}
      </>
    );
  }, [
    currentPasswordEntropy,
    password,
    // passwordFocused,
    // passwordLabel,
    passwordPopUp,
    handleChangePassword,
    handleFocusPassword,
    minPasswordEntropy,
    passwordErrorFirstSolved,
    passwordErrorSecondSolved,
    passwordErrorThirdSolved,
    // translate,
  ]);

  return (
    <div className="pg-login container" onKeyPress={handleEnterPress}>
      <Link to="/" className="l-link c-logo">
        <img src={"/img/mini-logo.png"} alt="Mini Logo" className="logo" />
        <span>{appTitle}</span>
      </Link>

      <div className="l-intro">
        <h2>New here?</h2>
        <p>Signing up is easy. It only takes a few steps</p>
      </div>

      {isUsernameEnabled() && (
        <Input
          placeholder="Username"
          value={username}
          handleChangeInput={handleChangeUsername}
          error={usernameError !== ""}
          errorMessage={usernameError}
          className={usernameError !== "" ? "error" : ""}
          id="username"
        />
      )}

      <Input
        type="text"
        id="email"
        placeholder="Email"
        handleChangeInput={handleChangeEmail}
        value={email}
        handleFocusInput={handleFocusEmail}
        error={emailError !== ""}
        errorMessage={emailError}
      />

      {renderPasswordInput()}

      <Input
        type="password"
        id="confirm_password"
        placeholder="Confirm Password"
        handleChangeInput={handleChangeConfirmPassword}
        value={confirmPassword}
        handleFocusInput={handleFocusConfirmPassword}
        error={(passwordError || confirmationError) !== ""}
        errorMessage={confirmationError || passwordError}
      />

      {/* <Input
        type="text"
        id="refferal_code"
        placeholder="Referral code ( Optional )"
        handleChangeInput={handleChangeRefId}
        value={refId}
      /> */}

      {captchaType !== "none" && renderCaptcha}

      {/* <div className="row space-around pp-ts">
        <Checkbox label="I agree to the " name="i_agree" checked={hasConfirmed} clickCheckBox={clickCheckBox} />
        <Link to="/terms" className="l-link">Terms of Serivce</Link> and
        <Link to="/privacy" className="l-link">Privacy Policy.</Link>
      </div> */}

      <Button
        type="primary"
        lable={isLoading ? "Loading..." : "Create"}
        size="large"
        className="x100"
        onClick={(e) => handleClick(e as any)}
        disabled={disableButton}
      />

      <span className="text-center">
        Already have an account?{" "}
        <Link to="/login" className="l-link">
          Login
        </Link>
      </span>
    </div>
  );
};

export const SignUpForm = React.memo(SignUpFormComponent);
