import { applyMiddleware,  createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './modules';
import { composeWithDevTools } from 'redux-devtools-extension';

const sagaMiddleware = createSagaMiddleware();
 
// tslint:disable-next-line:no-any
// const composeEnhancer: typeof compose = (window as any)
//     .__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(
//     rootReducer,
//     composeWithDevTools( applyMiddleware(sagaMiddleware) ),
//     composeEnhancer(
//         applyMiddleware(
//             sagaMiddleware
//         ),
//     ),

// ); 

const store = createStore(
    rootReducer,
    composeWithDevTools( applyMiddleware(sagaMiddleware) )
)
export {
    store,
    sagaMiddleware,
 };


