import React, { Component, RefObject } from "react";
import "./inputselect.scss";
import { Addon } from "../Addon";
import { ChevronDownIcon } from "../../assets/icons";

type Props = {
  selections?: string[];
  placeholder?: string;
  leftAddon?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  value?: string;
  handleChangeInput?: (value: string) => void;
  error?: boolean;
  errorMessage?: string;
  id: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
  handleFocusInput?: () => void;
};

interface State {
  showOptions: boolean;
}
export class InputSelect extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showOptions: false,
    };
    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  private handleChangeValue(value: string) {
    this.props.handleChangeInput && this.props.handleChangeInput(value);
  }

  private handleSelect() {

    if (this.props.disabled) {
      return
    }
    this.setState({ showOptions: !this.state.showOptions });
  }

  render() {
    const {
      leftAddon,
      placeholder,
      disabled,
      className,
      value,
      error,
      errorMessage,
      id,
      autoFocus,
      handleFocusInput,
    } = this.props;

    return (
      <>
        <div className={`input-group select ${className ? className : ""}`}
          onClick={this.handleSelect}
        >
          {leftAddon && <Addon content={leftAddon} className="addon-left" />}
          <input readOnly type="text" className="input-ol" placeholder={placeholder} value={value} />
          <Addon
            content={<ChevronDownIcon />}
            className="addon-right select-addon"
          />
          <ul className={`options_wrapper ${this.state.showOptions && "show"}`}>
            {this.props.selections?.map((selection) => (
              <li className="option" key={selection} onClick={() => this.handleChangeValue(selection)}>
                {selection}
              </li>
            ))}
          </ul>
          {error && <span className="input-error">{errorMessage}</span>}
        </div>
      </>
    );
  }
}

