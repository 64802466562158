import React, { useEffect } from "react";
import "./LeftSideBar.scss";
import { 
  DashboardIcon,
  DepositIcon,
  DollarIcon,
  HistoryIcon,
  ReferralsIcon,
  SettingsIcon, 
  WalletIcon,
  WithdrawIcon,
} from "../../assets/icons";
import { NavLink } from "react-router-dom";
import { ExchangeIcon } from "../../assets/icons/Exchange";
import { useDispatch, useSelector } from "react-redux";
import { selectUserGroup, userGroupFetch } from "../../modules";
 

export const LeftSideBar = () => {

  const userGroup = useSelector(selectUserGroup);
  const dispatch = useDispatch();

useEffect(() => {
  if (userGroup == "") {
     dispatch(userGroupFetch()); 
  }
    
}
, [userGroup]);

  return (
    <>
      <div className="left-sidebar">
        <ul className="left-sidebar-wrapper">
          {/* <li>
            <NavLink to="/dashboard">
              <DashboardIcon className="i24" />
              <span>Dashboard</span>
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/wallet">
              <WalletIcon className="i24" />
              <span>Wallet</span>
            </NavLink>
          </li>
          
          <li>
            <NavLink to="/exchange">
              <ExchangeIcon className="i24" />
              <span>Swap</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/deposit-histories">
              <DepositIcon className="i24" />
              <span>Deposit Histories</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/withdraw-histories">
              <WithdrawIcon className="i24" />
              <span>Withdraw Histories</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/swap-histories">
              <HistoryIcon className="i24" />
              <span>Swap Histories</span>
            </NavLink>
          </li>
        
      {userGroup !== "" && userGroup !== "vip-0"  && (
        <li>
          <NavLink to="/coupon-sale-histories">
            <DollarIcon className="i24" />
            <span>Merchant Sales</span>
          </NavLink>
        </li>
      )}
 
 
          
          {/*           
          <li>
            <NavLink to="/referrals">
              <ReferralsIcon className="i24" />
              <span>Refferals</span>
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/profile">
              <SettingsIcon className="i24" />
              <span>Settings</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};
