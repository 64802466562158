import React, { useEffect, useState } from "react";
import "./wallet.scss";

import {
  Button,
  CodeVerification,
  ContentPopup,
  Input,
  Status,
  Table,
  // ,Button, Radio
} from "../../components";
// import { Link } from "react-router-dom";
// import { MenuIcon } from "../../assets/icons";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { capitalize, copy, localeDate, truncateMiddle } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { Currency, Transaction, alertPush, currenciesFetch, selectCurrencies, selectUserInfo, selectWallets, selectWithdrawSuccess, transactionsFetch, walletsAddressFetch, walletsFetch, walletsWithdrawCcyFetch } from "../../modules";
import QRCode from "qrcode.react";
import { selectTransactions } from "../../modules/user/transactions/selectors";
import { Statuses } from "../../api";

export const Wallet = () => {
  const [defaultWallet, setDefaultWallet] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(0);


  const [withdrawAddress, setWithdrawAddress] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [amountError, setAmountError] = useState<string | undefined>(undefined)
  const [addressError, setAddressError] = useState<string | undefined>(undefined)
  const [showModal, setShowModal] = useState(false);
  const [otp, setOtp] = useState("");

  const wallets = useSelector(selectWallets);
  const user = useSelector((selectUserInfo));
  const transactions = useSelector(selectTransactions)
  const withdrawSuccess = useSelector(selectWithdrawSuccess)
  const currencies = useSelector(selectCurrencies);
  const dispatch = useDispatch();


  const evmTokenNames = {
    "eth": { name: "Ethereum", token_name: "ERC20-Token" },
    "bnb": { name: "Binance Smart Chain", token_name: "BEP20-Token" },
    "waves": { name: "Waves", token_name: "WAVES" },
  }

  const copyAddress = () => {
    copy("deposit-address");
    const payload = { message: ["Address Copied"], type: "success" };
    dispatch(alertPush({ ...payload }));
  };

  useEffect(() => {

    if (!currencies.length){
      dispatch(currenciesFetch())
    }

    if (!wallets.length) {
      dispatch(walletsFetch());
    }


    if (wallets.length && !defaultWallet) {
      setSelectedWallet(0)
      setDefaultWallet(true)
      selectWallet(0)

    }

    if (withdrawSuccess) {
      dispatch(transactionsFetch({ currency: wallets[selectedWallet]?.currency, limit: 10, }))
    }

    // eslint-disable-next-line
  }, [dispatch, wallets, selectedWallet, withdrawSuccess]);



  const handleWithdrawSubmit = () => {

    if (!user.otp) {
      dispatch(alertPush({ message: ["Please Enable 2FA"], type: "error" }))
      return
    }

    if (!withdrawAddress) {
      setAddressError('Address Required')
    }
    if (!withdrawAmount) {
      setAmountError('Amount Required')
    }
    if (!withdrawAddress && !withdrawAmount && !otp) {
      return
    }


    setShowModal(true)
  }

  const handleWithdrawAddressChange = (value: string) => {
    setWithdrawAddress(value)
    setAddressError('')
  }

  const handleWithrawAmountChange = (value: string) => {
    setWithdrawAmount(value)
    setAmountError('')
  }

  const handleChange2FACode = (value: string) => {
    setOtp(value)
  }

  const selectWallet = (index: number) => {
    setSelectedWallet(index)
    dispatch(transactionsFetch({ currency: wallets[index]?.currency, limit: 10, }))
    if (!wallets[index]?.deposit_address?.address) {
      dispatch(walletsAddressFetch({ currency: wallets[index]?.currency }))
    }
  }
  const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      withdrawSubmit();

    }
  };

  const clearInputs = () => {
    setWithdrawAddress("")
    setWithdrawAmount("")
    setOtp("")
    setAmountError("")
    setAddressError("")
  }

  const closeModal = () => {
    setShowModal(false);
    clearInputs()

  };
  const getExplorerLink = (currencies: Currency[], currency: string, txid: string) => {
    const currencyData = currencies.find((c) => c.id === currency);
    if (!currencyData) return "";
    return currencyData.explorer_transaction.replace("#{txid}", txid);
  }

  const withdrawSubmit = () => {
    dispatch(walletsWithdrawCcyFetch({ currency: wallets[selectedWallet].currency, otp: otp, address: withdrawAddress, amount: withdrawAmount }))
    closeModal()

  }

  const renderModalBody = () => {
    const isValid2FA = otp.match("^[0-9]{6}$");

    return (
      <>
        <CodeVerification
          code={otp}
          onChange={handleChange2FACode}
          codeLength={6}
          type="text"
          placeholder="x"
          inputMode="decimal"
          showPaste2FA={false}
          // message=" "
          onSubmit={handleEnterPress}
        //  isMobile={this.props.isMobile}
        />

        <div className="modal-submit-footer">
          <Button
            lable="Withdraw"
            type="primary"
            className="mt8 x100"
            disabled={!isValid2FA}
            onClick={withdrawSubmit}
          />
        </div>
      </>
    );
  };

  const renderEVMTokenBadge = () => {
    if (!wallets[selectedWallet]?.parent_id) return null
    const token = wallets[selectedWallet]?.parent_id || ""
    const tokenType = evmTokenNames[token]?.token_name || ""
    return <span className="badge badge-warning token-name">{tokenType}</span>
  }

  const modal2fa = showModal ? (
    <ContentPopup onClose={closeModal}>
      <div className="google-auth__wrapper grow">
        <h3 className="text-center mb16">2FA Verification</h3>
        {renderModalBody()}
      </div>
    </ContentPopup>
  ) : null;

  const walletAddress = wallets[selectedWallet]?.deposit_address?.address || "";
  const ticker = wallets[selectedWallet]?.currency?.toUpperCase() || "";
  const withdraw_fee = wallets[selectedWallet]?.fee || 0;

  const Column = [
    { title: "Date", style: { minWidth: 170 } },
    { title: "Address" },
    { title: "Type" },
    { title: "Amount" },
    { title: "Fee" },
    { title: "Conf" },
    { title: "TxID" },
    { title: "Status" },
  ];

  const renderCells = (histories: Transaction[]) =>
    histories.map((history, index) => {
      const { created_at, address, amount, state, confirmations, txid, type, fee,currency } = history;
      return (
        <tr key={index}>
          <td>{localeDate(created_at, 'fullDate')}</td>
          <td>{address}</td>
          <td>
            <span className={type.toLocaleLowerCase()}>{capitalize(type)}</span>
          </td>
          <td>{parseFloat(amount).toFixed(8)}</td>
          <td>{fee}</td>

          <td>{confirmations}</td>

          <td><a href={getExplorerLink(currencies, currency, txid)}>{truncateMiddle(txid, 12)}</a></td>

          {/* <td>
            <a className="l-link" href={"/tx/" + txid}>
              {truncateMiddle(txid, 12)}
            </a>
          </td> */}

          <td>
            <Status type={Statuses[state]} label={capitalize(state)} />
          </td>
        </tr>
      );
    });

  return (
    <>
      <div className="wallets_wrapper">
        <h3>Select Wallet</h3>
        <div className="wallets">
          {wallets.map((wallet, index) => {
            return (
              <div key={index} className={`walletitem ${selectedWallet === index ? "active" : ""}`} onClick={() => {
                selectWallet(index)
              }}  >
                <div className="walletitem_content">
                  <div className="walletitem_content__left">
                    <img src={wallet?.iconUrl} alt="" />
                  </div>
                  <div className="walletitem_content__right">
                    <span className="name">{wallet.name} ({wallet.currency.toLocaleUpperCase()})</span>
                    <span className="balance">{parseFloat(wallet.balance).toFixed(wallet.fixed)}</span>
                  </div>
                </div>
              </div>
            );
          })
          }

        </div>

      </div>
      {/* <Button type="primary" lable="Check it" size="large" /> */}

      <div className="container cx-center wallet bg-trans">
        <div className="row x100 bg-trans">
          <div className="withdraw-wrapper">
            <div className="withdraw-content">
              {renderEVMTokenBadge()}
              <h3 className="text-center">Withdraw {ticker}</h3>
              <Input
                placeholder={"Enter " + ticker + " Address"}
                value={withdrawAddress}
                handleChangeInput={handleWithdrawAddressChange}
                className="x100"
                error={addressError ? true : false}
              />
              <Input
                placeholder={"Amount"} className="x100"
                value={withdrawAmount} handleChangeInput={handleWithrawAmountChange}
                error={amountError ? true : false}
              />
              <Input
                placeholder={"Fees " + withdraw_fee + " " +
                  (wallets[selectedWallet]?.parent_id ? wallets[selectedWallet]?.parent_id?.toLocaleUpperCase() : ticker.toLocaleUpperCase())
                }
                disabled={true}
                className="x100"
              />
              <div className="withdraw_footer">
                <Button type="primary" lable="Withdraw" size="large" onClick={handleWithdrawSubmit} />
                <span>Total: {parseFloat(withdrawAmount) > 0 ? (parseFloat(withdrawAmount) - withdraw_fee).toFixed(wallets[selectedWallet].fixed) : 0} {ticker.toLocaleUpperCase()}</span>
              </div>
            </div>
          </div>
          <div className="deposit-wrapper">
            {renderEVMTokenBadge()}
            <div className="deposit-content">
              <h3>Deposit {ticker}</h3>
              <span>Your {ticker} Deposit Address</span>
              <QRCode value={walletAddress} className="qrcode" />

              <Input

                value={walletAddress}

                className="x100"
                id="deposit-address"
                rightAddon={<DocumentDuplicateIcon onClick={copyAddress} />}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="history">
            <h4>Last 10 Transactions</h4>
            <Table column={Column} tableRows={renderCells(transactions)} />
          </div>
        </div>
        {modal2fa}
      </div>
    </>
  );
};
