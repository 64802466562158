import React, { Component } from "react";
import "./status.scss";
// import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";

type Props = {
  label: string;
  type: "primary" | "success" | "danger" | "warning" | "info" | "default";
  className?: string;
};

export class Status extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const { type, className, label } = this.props;

    return (
      <>
        <span className={`status status-${type} ${className ? className : ""}`}>
          {label}
        </span>
      </>
    );
  }
}

// Enter Full Name
