import * as React from "react";

interface ExchangeIconProps {
  className?: string;
}

export const ExchangeIcon: React.FC<ExchangeIconProps> = (props: ExchangeIconProps) => {
  return (

    <>
      <svg className={props.className} width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.2929 0.792893C12.6834 0.402369 13.3166 0.402369 13.7071 0.792893L17.7071 4.79289C17.8946 4.98043 18 5.23478 18 5.5C18 5.76522 17.8946 6.01957 17.7071 6.20711L13.7071 10.2071C13.3166 10.5976 12.6834 10.5976 12.2929 10.2071C11.9024 9.81658 11.9024 9.18342 12.2929 8.79289L14.5858 6.5H5C4.44772 6.5 4 6.05228 4 5.5C4 4.94772 4.44772 4.5 5 4.5H14.5858L12.2929 2.20711C11.9024 1.81658 11.9024 1.18342 12.2929 0.792893ZM5.70711 10.7929C6.09763 11.1834 6.09763 11.8166 5.70711 12.2071L3.41421 14.5H13C13.5523 14.5 14 14.9477 14 15.5C14 16.0523 13.5523 16.5 13 16.5H3.41421L5.70711 18.7929C6.09763 19.1834 6.09763 19.8166 5.70711 20.2071C5.31658 20.5976 4.68342 20.5976 4.29289 20.2071L0.292893 16.2071C0.105357 16.0196 0 15.7652 0 15.5C0 15.2348 0.105357 14.9804 0.292893 14.7929L4.29289 10.7929C4.68342 10.4024 5.31658 10.4024 5.70711 10.7929Z" fill="var(--icon)" />
      </svg>




    </>
  );
};
