import React from "react";
import "./terms.scss";

// import { Link } from "react-router-dom"; 

export const TermsPage = () => {


  return (
    <>
      <div className="container terms-pg_wrapper">
        <div className="terms-pg">
          <h2 className="text-center">Terms of Services</h2>
           
        </div>
      </div>
    </>
  );
};

