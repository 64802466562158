import { CommonError } from '../../types'; 
import {
 
} from './constants';
import { Swap } from './types';
import {
    SWAPS_DATA,
    SWAPS_ERROR,
    SWAPS_FETCH,
} from './constants';
import { SwapsAction } from './actions';

export interface SwapsState {
 
    list: Swap[];
    loading: boolean; 
    swapSuccess: boolean;
    error?: CommonError; 
 
}

export const initialSwapsState: SwapsState = {
    list: [],
    loading: false,
    swapSuccess: false,
}; 

 

export const swapsReducer = (state: SwapsState = initialSwapsState, action: SwapsAction) => {
    switch (action.type) {
        case SWAPS_FETCH:
            return { ...state, loading: true };
        case SWAPS_DATA:
            return {
                 ...state, 
                 loading: false, 
                 list: action.payload };
        case SWAPS_ERROR:
            return { 
                ...state, 
                loading: false, 
                error: action.error };
        default:
            return state;
    }
}
