import React, { useEffect } from "react";
import "./emailverification.scss";
import { Button } from "../../components";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";

import {
  emailVerificationFetch,
  // emailVerificationResendFetch,
  selectEmailVerified,
} from "../../modules";
import { appTitle } from "../../api";
import { EMAIL_REGEX } from "../../helpers";
import { selectConfigs } from "../../modules/public/configs";
import {
  resetCaptchaState,
  selectCaptchaResponse,
  selectGeetestCaptchaSuccess,
  selectRecaptchaSuccess,
} from "../../modules/user/captcha";

export const EmailVerificationPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();
  const location = useLocation();
  const emailVerified = useSelector(selectEmailVerified);

  const configs = useSelector(selectConfigs);
  const geetestCaptchaSuccess = useSelector(selectGeetestCaptchaSuccess);
  const reCaptchaSuccess = useSelector(selectRecaptchaSuccess);
  const captcha_response = useSelector(selectCaptchaResponse);

  useEffect(() => {
    if (emailVerified) {
      history.push("/wallet");
    }
  }, [emailVerified, history, token]);

  const handleSubmit = () => {
    switch (configs.captcha_type) {
      case "recaptcha":
      case "geetest":
        dispatch(
          emailVerificationFetch({
            email: location.state?.email,
            captcha_response,
          })
        );
        break;
      default:
        dispatch(
          emailVerificationFetch({
            email: location.state?.email,
          })
        );
        break;
    }

    dispatch(resetCaptchaState());
  };

  const disableButton = (): boolean => {
    //   location,

    if (
      location.state &&
      location.state?.email &&
      !location.state.email.match(EMAIL_REGEX)
    ) {
      return true;
    }

    if (configs.captcha_type === "recaptcha" && !reCaptchaSuccess) {
      return true;
    }

    if (configs.captcha_type === "geetest" && !geetestCaptchaSuccess) {
      return true;
    }

    return false;
  };

  return (
    <>
      <div className="pg-login container">
        <Link to="/" className="l-link c-logo">
          <img src={"/img/mini-logo.png"} alt="Mini Logo" className="logo" />
          <span>{appTitle}</span>
        </Link>
        <div className="l-intro">
          <h2> Email Verification</h2>
        </div>

        <span>
          We have sent verification link to your email address, check and verify
          your account.
        </span>

        <Button
          type="primary"
          lable="Resend Verification Link"
          className="x100"
          size="large"
          onClick={handleSubmit}
          disabled={disableButton()}
        />
      </div>
    </>
  );
};
