import React from "react";

import { Button, CodeVerification } from "../../components";
import "./TwoFactorAuthScreen.scss";

import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { withRouter } from "react-router-dom";

import { History } from "history";
import { CloseIcon } from "../../assets/icons";
import { copy, CopyableTextField, Panel } from "../../components";
import {
  alertPush,
  RootState,
  // , selectMobileDeviceState
} from "../../modules";
import {
  generate2faQRFetch,
  selectTwoFactorAuthBarcode,
  selectTwoFactorAuthQR,
  selectTwoFactorAuthSuccess,
  toggle2faFetch,
} from "../../modules/user/profile";

interface RouterProps {
  history: History;
}

interface ReduxProps {
  barcode: string;
  qrUrl: string;
  success?: boolean;
  // isMobileDevice: boolean;
}

interface DispatchProps {
  toggle2fa: typeof toggle2faFetch;
  generateQR: typeof generate2faQRFetch;
  fetchSuccess: typeof alertPush;
}

type Props = RouterProps & ReduxProps & DispatchProps;

interface State {
  otpCode: string;
}

class GoogleAuthenticator extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      otpCode: "",
    };
  }

  public componentDidMount() {
    const enable2fa = this.get2faAction();
    if (enable2fa) {
      this.props.generateQR();
    }
  }

  public componentWillReceiveProps(next: Props) {
    if (!this.props.success && next.success) {
      this.handleNavigateToProfile();
    }
  }

  public doCopy = () => {
    copy("secret-2fa");
    this.props.fetchSuccess({
      message: ["page.body.wallets.tabs.deposit.ccy.message.success"],
      type: "success",
    });
  };

  public render() {
    const enable2fa = this.get2faAction();
    return (
      <>
        <div className="pg-dashboard">
          <div className="pg-main">
            <div className="profile_google_auth_wrapper">
              <div className="profile_google_auth_content center">
                <Panel
                  title="Google Authenticator"
                  panelContent={this.renderToggle2fa(enable2fa)}
                  headerRightContent={this.renderBack()}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  private renderBack = () => {
    return (
      <div className="back_steps" onClick={this.goBack}>
        <CloseIcon color="black" className="icon" />
      </div>
    );
  };
  private renderToggle2fa = (enable2fa: boolean) => {
    const { barcode, qrUrl } = this.props;
    const { otpCode } = this.state;

    const secretRegex = /secret=(\w+)/;
    const secretMatch = qrUrl.match(secretRegex);
    const secret = secretMatch ? secretMatch[1] : null;
    const submitHandler = enable2fa
      ? this.handleEnable2fa
      : this.handleDisable2fa;

    return (
      <>
        <div className="auth_qr_wrapper">
          <div className="auth_qr_hint text-left">
            <p>
              1 Download and install Google Authenticator application from{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/app/google-authenticator/id388497605?mt=8"
              >
                AppStore
              </a>{" "}
              or{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl"
              >
                Google play
              </a>
            </p>
            <p>
              2 Scan QR code or use secret MFA code: * Save this secret in a
              secure location. This code can be used to gain 2FA access from a
              different device.
            </p>
          </div>
          {this.renderTwoFactorAuthQR(barcode)}
        </div>
        <div className="auth_secret_wrapper">
          <h3 className="text-center mb8">Secrete Key</h3>
          <div className="auth_secret_details center">
            <fieldset onClick={this.doCopy} className="x100 br4">
              {secret && (
                <CopyableTextField
                  value={secret}
                  fieldId="secret-2fa"
                  label=""
                  copyButtonText="Copy"
                  inputType="sl__input"
                />
              )}
            </fieldset>
          </div>
        </div>
        <div className="auth_input_wrapper text-center mt8">
          <CodeVerification
            code={otpCode}
            onChange={this.handleOtpCodeChange}
            codeLength={6}
            type="text"
            placeholder="x"
            inputMode="decimal"
            showPaste2FA={false}
            onSubmit={this.handleEnterPress}

            //  isMobile={this.props.isMobile}
          />
        </div>
        <div className="auth_button_wrapper center">
          {/* <button className="btn btn_primary grow" onClick={submitHandler}>
            Enable
          </button> */}
          <Button
            lable="Enable"
            type="primary"
            className="x100"
            onClick={submitHandler}
          />
        </div>
      </>
    );
  };

  private renderTwoFactorAuthQR = (barcode: string) => {
    const src = `data:image/png;base64,${barcode}`;
    return (
      barcode.length > 0 && (
        <div className="auth_qr_code">
          <img alt="" src={src} />
        </div>
      )
    );
  };

  private handleOtpCodeChange = (value: string) => {
    this.setState({
      otpCode: value,
    });
  };

  private handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const enable2fa = this.get2faAction();
    const submitHandler = enable2fa
      ? this.handleEnable2fa
      : this.handleDisable2fa;
    if (event.key === "Enter") {
      event.preventDefault();
      submitHandler();
    }
  };

  private handleEnable2fa = () => {
    this.props.toggle2fa({
      code: this.state.otpCode,
      enable: true,
    });
  };

  private handleDisable2fa = () => {
    this.props.toggle2fa({
      code: this.state.otpCode,
      enable: false,
    });
  };

  private handleNavigateToProfile = () => {
    this.props.history.push("/profile");
  };

  private get2faAction = () => {
    const routingState = this.props.history.location.state as any;

    return routingState ? routingState.enable2fa : false;
  };

  private goBack = () => {
    window.history.back();
  };
}

const mapStateToProps: MapStateToProps<ReduxProps, Props, RootState> = (
  state
) => ({
  qrUrl: selectTwoFactorAuthQR(state),
  barcode: selectTwoFactorAuthBarcode(state),
  success: selectTwoFactorAuthSuccess(state),
  // isMobileDevice: selectMobileDeviceState(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch
) => ({
  generateQR: () => dispatch(generate2faQRFetch()),
  toggle2fa: ({ code, enable }) => dispatch(toggle2faFetch({ code, enable })),
  fetchSuccess: (payload) => dispatch(alertPush(payload)),
});

const GoogleAuthenticatorConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleAuthenticator);
// tslint:disable-next-line:no-any
const GoogleAuthenticatorScreen = withRouter(
  GoogleAuthenticatorConnected as any
);

export { GoogleAuthenticatorScreen };
