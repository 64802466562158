// tslint:disable-next-line
import { call, put } from 'redux-saga/effects';
import { API, RequestOptions } from '../../../../api';
import {
    userData,
    userError,
} from '../actions';
import { signUpRequireVerification } from '../../auth';
// import { alertPush } from '../../../public/alert';
// import { signInRequire2FA,
//     // verificationSuccess 
// } from '../../auth';

const userOptions: RequestOptions = {
    apiVersion: 'auth',
};

export function* userSaga() {
    try {
        const user = yield call(API.get(userOptions), '/resource/users/me');
        const payload = {
            user: user,
        };

 
        yield put(userData(payload));

        if (user.state === 'pending') {
            yield put(signUpRequireVerification({ requireVerification: true }));
        }

        


        // console.log(user)
        // // const user = yield call(API.get(userOptions), '/resource/users/me');
        // // const payload = {
        // //     user: user,
        // // };
        // const data = yield call(API.get(userOptions), '/resource/users/me');
        // // console.log(data)

        // if(data.authenticator){

        //     yield put(signInRequire2FA({ require2fa: true }));
        //   }
          
        //     //   console.log(data)
        // //   if(data.email_verified_at !== null){

        // //     yield put(verificationSuccess());
        // //   }
      
        // // const level = data.email_verified_at != null ? 1 : 0;

        // const user = {...data};


        // yield put(userData({ user }));

        // yield put(userData(payload));
    } catch (error) {
        // if (error.code === 422) {
        //     for (let [key] of Object.entries(error.message)) {
        //         yield put(alertPush({ type: 'error', message: [error.message[key][0]] }))
        //     }

        // }
        yield put(userError(error));
    }
}
