import React from "react";

import "./mytask.scss";
import { Button, MyTaskCard } from "../../components";

const allTask = [
  // {
  //   id: 1,
  //   title: 'Like Video',
  //   platform: 'youtube',
  //   available_task: '10',
  //   status: 'enabled'
  // },
  // {
  //   id: 2,
  //   title: 'Share Video',
  //   platform: 'youtube',
  //   available_task: '5',
  //   status: 'disabled'
  // }



]
export const MyTask = () => {

  return (
    <>
      <div className=" container cx-center my-task bg-trans ">
        <div className="col my-task_wrapper">

          <h3>My Task</h3>
          <Button type="primary" lable="Add Task" size="medium" className="fit " />
          <div className="task-wrapper">
            {
              allTask.map(data => {
                const { id, title, platform, available_task, status } = data
                return (
                  <MyTaskCard status={status} availableTask={available_task} key={id} platform={platform} title={title} />
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  );
};
