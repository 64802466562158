import { RootState } from '../../index';
import { TransactionsState } from './reducer';
import { Transaction } from './types';

const selectTransactionsState = (state: RootState): TransactionsState => state.user.transactions;

export const selectTransactions = (state: RootState): Transaction[] =>
    selectTransactionsState(state).list;

export const selectTransactionsLoading = (state: RootState): boolean | undefined =>
    selectTransactionsState(state).loading;
