import React from "react";
import "./popup.scss";
interface props {
  onClose?: Function;
  children: any;
}

export const ContentPopup: React.FC<props> = (props: props) => {
  const { onClose } = props;
  function close() {
    const winPop = document.querySelector(".popup_screen_wrapper")!;
    winPop.classList.toggle("hide");
    if (onClose) {
      onClose();
    }
  }

  return (
    <>
      <>
        <div className="popup_screen_wrapper">
          <div className="popup_screen_content">
            {/* close popup screen */}
            <button className="popup_close" onClick={() => close()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
              </svg>
            </button>
            {/* popup content here */}
            {props.children}
          </div>
        </div>
      </>
    </>
  );
};
// export function ContentPopup(props: props) {
//   function close() {
//     const winPop = document.querySelector(".popup_screen_wrapper")!;
//     // winPop.remove();
//     winPop.classList.toggle("hide");
//   }
//   return (
//     <>
//       <div className="popup_screen_wrapper">
//         <div className="popup_screen_content">
//           {/* close popup screen */}
//           <button className="popup_close" onClick={() => close()}>
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               height="24"
//               viewBox="0 0 24 24"
//               width="24"
//             >
//               <path d="M0 0h24v24H0z" fill="none" />
//               <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
//             </svg>
//           </button>
//           {/* popup content here */}
//           {props.children}
//         </div>
//       </div>
//     </>
//   );
// }
