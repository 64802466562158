// import "@openware/cryptofont";
import classnames from "classnames";
import * as React from "react";
import { Button, Input } from "../";
import { copy } from "../../helpers";
import "./copyabletextfield.scss";

export interface CopyableTextFieldProps {
  /**
   * Text value that will be copied to the clipboard
   */
  value: string;
  /**
   * Additional class name for styling. By default element receives `cr-button` class
   * @default empty
   */
  className?: string;
  /**
   * String value that makes copy field be unique
   */
  fieldId: string;
  /**
   * @default 'Copy'
   *  Renders text of the label of copy button component
   */
  copyButtonText?: string;
  /**
   * @default 'false'
   * If true, Button will be disabled.
   */
  disabled?: boolean;
  label?: string;

  inputType: "fl__input" | "sl__input";
}

/**
 * Text field component with ability to copy inner text.
 */
class CopyableTextField extends React.Component<CopyableTextFieldProps> {
  public componentDidMount() {
    if (!this.props.fieldId) {
      throw new Error("CopyableTextField must contain `fieldId` prop");
    }
  }

  public render() {
    const {
      value,
      className,
      disabled,
      fieldId,
      copyButtonText,
      label,
      // inputType,
    } = this.props;
    const doCopy = () => copy(fieldId);
    const cx = classnames("copyable-text-field", className);

    return (
      <div className={cx}>
        <Input
          id={String(fieldId)}
          // readOnly={true}
          value={value}
          // ={doCopy}
          type="text"
          disabled={disabled}
          // label={label || ''}
          // defaultLabel={label || ''}
          placeholder={label || ""}
          // classNameGroup={inputType}
          // labelVisible={label !== '' ? true : false}
        />

        {copyButtonText && (
          <Button
            onClick={doCopy}
            disabled={disabled}
            lable={copyButtonText ? copyButtonText : "Copy"}
            type="primary"
          />
        )}
      </div>
    );
  }
}

export { CopyableTextField, copy };
