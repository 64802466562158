import React, { Component } from "react";
import "./button.scss";

// import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";

type Props = {
  type: "primary" | "secondary" | "success" | "danger" | "warning" | "default";
  size?: "small" | "medium" | "large" | "xl";
  lable?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export class Button extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      lable,
      type,
      leftIcon,
      rightIcon,
      className,
      size,
      onClick,
      disabled,
    } = this.props;

    return (
      <>
        <button
          className={`btn btn-${type} ${size ? "btn-" + size : "btn-medium"}  ${
            className ? className : ""
          } `}
          onClick={onClick}
          disabled={disabled}
        >
          {leftIcon ? leftIcon : ""}
          {lable ? lable : ""}
          {rightIcon ? rightIcon : ""}
        </button>
      </>
    );
  }
}

// Enter Full Name
