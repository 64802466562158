import React, { useEffect, useState } from "react";
import "./deposithistory.scss";

import {

  Pagination,
  Status,
  Table,
  // ,Button, Radio
} from "../../components";
// import { Link } from "react-router-dom";
// import { MenuIcon } from "../../assets/icons";

import { capitalize, localeDate, truncateMiddle } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import {

  Currency,
  currenciesFetch,
  // HistoryList, Transaction,
  fetchHistory, resetHistory, selectCurrencies, selectCurrentPage, selectHistory, selectHistoryTotal,
} from "../../modules";
import { Statuses } from "../../api";

export const DepositHistory = () => {
  const dispatch = useDispatch()

  const [paginationLimit, setPaginationLimit] = React.useState(10);

  const histories = useSelector(selectHistory) 
  const type = 'deposits'
  const currentPage = useSelector(selectCurrentPage);
  const depositsTotal = useSelector(selectHistoryTotal);

  // const [initiaFetch, setInitiaFetch] = useState(false)
  const currencies = useSelector(selectCurrencies);

  useEffect(() => {

    if (!currencies.length){
      dispatch(currenciesFetch())
    }

    dispatch(fetchHistory({
      type: type,
      limit: paginationLimit,
      page: 0,
    }));
 
    return () => {
      // cleanup
      dispatch(resetHistory());
    };
  }, [dispatch, paginationLimit, type]);

  const getExplorerLink = (currencies: Currency[], currency: string, txid: string) => {
    const currencyData = currencies.find((c) => c.id === currency);
    if (!currencyData) return "";
    return currencyData.explorer_transaction.replace("#{txid}", txid);
  }
  


  const Column = [
    { title: "Date", style: { minWidth: 170 } },
    // { title: "Address" },
    // { title: "Type" },
    { title: "Amount" },
    { title: "Currency" },
    // { title: "Fee" },
    { title: "Conf" },
    { title: "TxID" },
    { title: "Status" },
  ];

  const renderCells = (histories) =>
    histories.map((history, index) => {
      const { created_at,
        // address,
        amount, state, confirmations, txid, currency } = history;
      return (
        <tr key={index}>
          <td>{localeDate(created_at, 'fullDate')}</td>
          {/* <td>{address}</td> */} 
          <td>{parseFloat(amount).toFixed(8)}</td>
          <td>{currency?.toUpperCase()}</td>
          {/* <td>{fee}</td> */}

          <td>{confirmations}</td>
          <td><a href={getExplorerLink(currencies, currency, txid)}>{truncateMiddle(txid, 10)}</a></td>
          <td>
            <Status type={Statuses[state]} label={state && capitalize(state)} />
          </td>
        </tr>
      );
    });


  const nextPageExists = (depositsTotal / paginationLimit) > currentPage;
  const firstElemIndex = (currentPage - 1) * paginationLimit + 1;
  const lastElemIndex = (currentPage - 1) * paginationLimit + histories.length;


  const onClickNextPage = () => {
    dispatch(fetchHistory({ page: (currentPage - 1) + 1, limit: paginationLimit, type: type }));
  };

  const onClickPrevPage = () => {
    dispatch(fetchHistory({ page: (currentPage - 1) - 1, limit: paginationLimit, type: type }));

  };

  return (
    <>
      <div className="container cx-center history bg-trans">

        <div className="row">
          <div className="history">
            <h3>Deposit Histories</h3>
            <Table column={Column} tableRows={renderCells(histories)} />

            {
              histories.length > 0 && <Pagination
                firstElemIndex={firstElemIndex}
                total={depositsTotal}
                lastElemIndex={lastElemIndex}
                page={currentPage}
                nextPageExists={nextPageExists}
                onClickPrevPage={onClickPrevPage}
                onClickNextPage={onClickNextPage}
              />
            }
          </div>
        </div>

      </div>
    </>
  );
};
