import { RootState } from '../../';
import { CouponCheck } from './types';

export const selectCurrentCoupon = (state: RootState): CouponCheck =>
    state.user.coupons.current;

export const selectCurrentCouponLoading = (state: RootState): boolean =>
    state.user.coupons.loading;

export const selectCurrentCouponValid = (state: RootState): boolean =>
    state.user.coupons.isValid;
export const selectIsCouponCode = (state: RootState): boolean =>   
    state.user.coupons.isCouponCode;
    
// export const selectCurrentCouponError = (state: RootState): string | undefined =>
//     state.user.coupons.error;

 