// tslint:disable-next-line no-submodule-imports
import { combineReducers } from "redux";
import { all, call } from "redux-saga/effects";
import { publicReducer, userReducer } from "./app";
import { AlertState, rootHandleAlertSaga } from "./public/alert";
import { ErrorHandlerState, rootErrorHandlerSaga } from "./public/errorHandler";
import { AuthState } from "./user/auth/reducer";
import { ProfileState, rootProfileSaga } from "./user/profile";
import {
  EmailVerificationState,
  rootEmailVerificationSaga,
} from "./user/emailVerification";
import { PasswordState, rootPasswordSaga } from "./user/password";
import { rootAuthSaga } from "./user/auth";
import { LanguageState } from "./public/i18n";
import { ConfigsState, rootConfigsSaga } from "./public/configs";
import { GeetestCaptchaState, rootGeetestCaptchaSaga } from "./user/captcha";
import { CurrenciesState, rootCurrenciesSaga } from "./public/currencies";
import { WalletsState, rootWalletsSaga } from "./user/wallets";
import { TransactionsState, rootTransactionsSaga } from "./user/transactions";
import { HistoryState, rootHistorySaga } from "./user/history";
import { SwapsState, rootSwapsSaga } from "./user/swaps";
import { CouponsCheckState, rootCouponsCheckSaga } from "./user/coupons";


export * from "./public/alert";
export * from "./public/errorHandler";
export * from "./public/configs";
export * from "./public/i18n";
export * from "./public/currencies";

export * from "./user/auth";
export * from "./user/password";
export * from "./user/profile";
export * from "./user/emailVerification";
export * from "./user/captcha";
export * from "./user/wallets";
export * from "./user/transactions";
export * from "./user/history";
export * from "./user/swaps";
export * from "./user/coupons";

export interface RootState {
  public: {
    configs: ConfigsState;
    alerts: AlertState;
    errorHandler: ErrorHandlerState;
    i18n: LanguageState;
    currencies: CurrenciesState;
  };
  user: {
    auth: AuthState;
    password: PasswordState;
    profile: ProfileState;
    sendEmailVerification: EmailVerificationState;
    captcha: GeetestCaptchaState;
    wallets: WalletsState;
    transactions: TransactionsState;
    history: HistoryState;
    swaps: SwapsState;
    coupons: CouponsCheckState;
  };
}

export const rootReducer = combineReducers({
  public: publicReducer,
  user: userReducer,
});

export function* rootSaga() {
  yield all([
    call(rootConfigsSaga),
    call(rootHandleAlertSaga),
    call(rootErrorHandlerSaga),
    call(rootPasswordSaga),
    call(rootEmailVerificationSaga),
    call(rootProfileSaga),
    call(rootAuthSaga),
    call(rootGeetestCaptchaSaga),
    call(rootCurrenciesSaga),
    call(rootWalletsSaga),
    call(rootTransactionsSaga),
    call(rootHistorySaga),
    call(rootSwapsSaga),
    call(rootCouponsCheckSaga),
  ]);
}
