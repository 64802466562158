import React, { useEffect, useState } from "react";
import "./login.scss";
import { Button, Captcha, Checkbox, Input } from "../../components";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRequireVerification,
  selectSignInError,
  selectSignInRequire2FA,
  selectUserFetching,
  signIn,
  signInError,
  signInRequire2FA,
  signUpRequireVerification,
} from "../../modules";
import { EMAIL_REGEX } from "../../helpers";
import { appTitle } from "../../api";
import { TwoFactorAuth } from "../../components/TwoFactorAuth";
import { selectConfigs } from "../../modules/public/configs";
import {
  selectCaptchaResponse,
  selectGeetestCaptchaSuccess,
  selectRecaptchaSuccess,
} from "../../modules/user/captcha";

export const LoginPage = () => {
  const dispatch = useDispatch();
  const configs = useSelector(selectConfigs);
  const require2FA = useSelector(selectSignInRequire2FA);
  const captcha_response = useSelector(selectCaptchaResponse);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [error2fa, setError2FA] = useState("");
  const [codeFocused, setCodeFocused] = useState(false);

  const geetestCaptchaSuccess = useSelector(selectGeetestCaptchaSuccess);
  const reCaptchaSuccess = useSelector(selectRecaptchaSuccess);

  const loginError = useSelector(selectSignInError);

  const loading = useSelector(selectUserFetching);
  const history = useHistory();
  const emailVerified = useSelector(selectRequireVerification);

  useEffect(() => {
    if (emailVerified) {
      history.push("/verify-email", { email: email });
    }

    dispatch(signInError({ code: 0, message: [""] }));
    dispatch(signUpRequireVerification({ requireVerification: false }));
  }, [emailVerified, history, email]);

  const handleChangeEmail = (value: string) => {
    if (!EMAIL_REGEX.test(value)) {
      setErrorEmail("Invlid email address");
    } else {
      setErrorEmail("");
    }
    setEmail(value);
  };

  const handleChangePassword = (value: string) => {
    setPassword(value);
  };

  const handleLogin = () => {
    if (errorEmail === "") {
      dispatch(
        signIn({
          email: email,
          password: password,
        })
      );
    }
  };

  const handleChangeOtpCode = (value: string) => {
    setError2FA("");
    setOtpCode(value);
  };

  const handle2faFocus = () => {
    setCodeFocused(!codeFocused);
  };

  const handleClose = () => {
    dispatch(signInRequire2FA({ require2fa: false }));
  };

  const isButtonDisabled = (): boolean => {
    return configs.captcha_type !== "none" &&
      !reCaptchaSuccess &&
      !geetestCaptchaSuccess
      ? true
      : false;
  };

  const handle2FASignIn = () => {
    const { captcha_type } = configs;

    if (!otpCode) {
      setError2FA("Please enter 2fa code");
    } else {
      if (captcha_type !== "none") {
        dispatch(
          signIn({
            email,
            password,
            captcha_response,
            otp_code: otpCode,
          })
        );
      } else {
        dispatch(signIn({ email, password, otp_code: otpCode }));
      }
    }
  };

  const renderLogin = () => {
    return (
      <>
        <div className="pg-login container">
          <Link to="/" className="l-link c-logo">
            <img src={"/img/mini-logo.png"} alt="Mini Logo" className="logo" />
            <span>{appTitle}</span>
          </Link>

          <div className="l-intro">
            <h2>Let's get started</h2>
            <p>Sign in to continue.</p>
          </div>
          <Input
            placeholder="Email"
            id="email"
            value={email}
            handleChangeInput={handleChangeEmail}
            error={errorEmail !== ""}
            errorMessage={errorEmail}
            className={errorEmail !== "" ? "error" : ""}
          />
          <Input
            placeholder="Password"
            id="password"
            value={password}
            type="password"
            handleChangeInput={handleChangePassword}
          />

          {configs.captcha_type !== "none" && renderCaptcha()}

          <div className="row space-between">
            <Checkbox label="Remember Me" name="remember_me" />
            <span className="text-center">
              <Link to="/forgot-password" className="l-link">
                Forgot password?
              </Link>
            </span>
          </div>

          <Button
            type="primary"
            lable="SIGN IN"
            size="large"
            className="x100"
            onClick={handleLogin}
            disabled={isButtonDisabled()}
          />
          <span className="text-center">
            Don't have an account?{" "}
            <Link to="/signup" className="l-link">
              Register
            </Link>
          </span>
        </div>
      </>
    );
  };

  const render2FA = () => {
    return (
      <>
        <div className="pg-login container">
          <Link to="/" className="l-link c-logo">
            <img src={"/img/mini-logo.png"} alt="Mini Logo" className="logo" />
            <span>{appTitle}</span>
          </Link>

          <TwoFactorAuth
            isLoading={loading}
            onSubmit={handle2FASignIn}
            title={"2FA Authentication"}
            // label={this.props.intl.formatMessage({ id: 'page.body.wallets.tabs.withdraw.content.code2fa' })}
            buttonLabel={"Verify"}
            message={" "}
            codeFocused={codeFocused}
            otpCode={otpCode}
            error={error2fa}
            handleOtpCodeChange={handleChangeOtpCode}
            handleChangeFocusField={handle2faFocus}
            handleClose2fa={handleClose}
          />
        </div>
      </>
    );
  };

  const renderCaptcha = () => {
    return <Captcha error={loginError} />;
  };
  return <>{require2FA ? render2FA() : renderLogin()}</>;
};
