import React, { useState } from "react";
import "./profile.scss";
import {
  Button,
  CodeVerification,
  ContentPopup,
  Input,
  Status,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordFetch,
  selectUserInfo,
  toggle2faFetch,
} from "../../modules";
import { localeDate } from "../../helpers";
// import { Input } from "../../components";
import {
  useHistory,
  //  Link
} from "react-router-dom";
// import { MenuIcon } from "../../assets/icons";
// import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";

export const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserInfo);
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [code2FA, setCode2FA] = useState("");

  const [new_password, SetNewPassword] = useState("");
  const [confirm_password, SetConfirmPassword] = useState("");

  const [old_password, SetOldPassword] = useState("");

  const [confirm_password_Error, setConfirmPasswordError] = useState("");

  const handleChange2FACode = (value: string) => {
    setCode2FA(value);
  };

  const handleNavigateTo2fa = (enable2fa: boolean) => {
    if (enable2fa) {
      history.push("/security/2fa", { enable2fa });
    } else {
      setShowModal(!showModal);
    }
  };

  const handleDisable2FA = () => {
    dispatch(
      toggle2faFetch({
        code: code2FA,
        enable: false,
      })
    );
    closeModal();
    handleChange2FACode("");
  };

  const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleDisable2FA();
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleChangeOldPassword = (value) => {
    SetOldPassword(value);
  };

  const handleChangeNewPassword = (value) => {
    SetNewPassword(value);
  };

  const handleChangeConfirmPassword = (value) => {
    if (new_password !== value) {
      setConfirmPasswordError("Password do not match");
    } else {
      setConfirmPasswordError("");
    }
    SetConfirmPassword(value);
  };

  const handleSubmitChangePassword = () => {
    const payload = {
      old_password,
      new_password,
      confirm_password,
    };

    dispatch(changePasswordFetch(payload));

    SetOldPassword("");
    SetNewPassword("");
    SetConfirmPassword("");
  };

  const renderModalBody = () => {
    const isValid2FA = code2FA.match("^[0-9]{6}$");

    return (
      <>
        <CodeVerification
          code={code2FA}
          onChange={handleChange2FACode}
          codeLength={6}
          type="text"
          placeholder="x"
          inputMode="decimal"
          showPaste2FA={false}
          // message=" "
          onSubmit={handleEnterPress}
        //  isMobile={this.props.isMobile}
        />

        <div className="modal-submit-footer">
          <Button
            lable="Disable"
            type="primary"
            className="mt8 x100"
            disabled={!isValid2FA}
            onClick={handleDisable2FA}
          />
        </div>
      </>
    );
  };

  const modal2fa = showModal ? (
    <ContentPopup onClose={closeModal}>
      <div className="google-auth__wrapper grow">
        <h3 className="text-center mb16">2FA Verification</h3>
        {renderModalBody()}
      </div>
    </ContentPopup>
  ) : null;

  return (
    <>
      <div className=" container cx-center bg-trans ">
        <div className="row settings">
          {modal2fa}
          {/* Profile Settings */}
          <div className="settings_content">
            <div className="col">
              <h3>User Details</h3>
              <label htmlFor="username">Username</label>
              <Input value={user.username} disabled className="x100" id="username" />
              <label htmlFor="email">Email Address</label>
              <Input value={user.email} disabled className="x100" id="email" />
              <label htmlFor="joined_date">Registered Date</label>
              <Input
                value={localeDate(user.created_at, "fullDate")}
                disabled
                className="x100"
              />

              <div className="g-auth_role">
                <div className="g-auth">
                  <h3>Google Authenticator</h3>
                  <div className="g-auth_content">
                    <Status
                      label={user.otp ? "Enable" : "Disabled"}
                      type={user.otp ? "success" : "danger"}
                    />
                    <Button
                      lable={user.otp ? "Disabled" : "Enable"}
                      type="primary"
                      className="fit"
                      size="small"
                      onClick={() => handleNavigateTo2fa(!user.otp)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <h3>Change Password</h3>
              <label htmlFor="old_password">Old Password</label>
              <Input
                value={old_password}
                placeholder="Old Password"
                handleChangeInput={handleChangeOldPassword}
                type="password"
                className="x100"
                id="old_password"
              />
              <label htmlFor="new_password">New Password</label>
              <Input
                value={new_password}
                handleChangeInput={handleChangeNewPassword}
                placeholder="New Password"
                type="password"
                className="x100"
                id="new_password"
              />
              <label htmlFor="confirm_password">Confirm Password</label>
              <Input
                value={confirm_password}
                type="password"
                handleChangeInput={handleChangeConfirmPassword}
                placeholder="Confirm Password"
                id="confirm_password"
                className={
                  confirm_password_Error !== "" ? "x100 error" : "x100"
                }
                error={confirm_password_Error !== ""}
                errorMessage={confirm_password_Error}
              />
              <Button
                className="fit mt24"
                type="primary"
                lable="Change"
                onClick={handleSubmitChangePassword}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
