import { call, put } from 'redux-saga/effects';
import { API, defaultStorageLimit, RequestOptions } from '../../../../api';
import { getHistorySagaParam, sliceArray } from '../../../../helpers';
import { sendError } from '../../../public/errorHandler';
import {
    failHistory,
    // failHistory,
    HistoryFetch, successHistory
} from '../actions';

const config: RequestOptions = {
    apiVersion: 'app',
    withHeaders: true,
};

export function* historySaga(action: HistoryFetch) {
    try {
        const { type, limit, page } = action.payload;
        const coreEndpoint = {
            deposits: '/account/deposits',
            withdraws: '/account/withdraws',
            swaps: '/account/swaps/histories',
            coupon_sales: '/account/coupon-sales-history',
            // presales: '/account/presale/histories',
            // transfers: '/account/internal_transfers',
        };
        const params = getHistorySagaParam(action.payload);
        const response = yield call(API.get(config), `${coreEndpoint[type]}?${params}`);
        const { data, headers } = response;
        const { total, page: currentPage } = headers;

        const nextPageExists = Number(total) > Number(currentPage) * Number(limit);

        yield put(successHistory({
            list: data,
            page: parseInt(currentPage),
            total: parseInt(total),
            nextPageExists
        }));

    } catch (error) {
        console.log("error", error)
        yield put(sendError({
            error,
            processingType: 'alert',
            extraOptions: {
                actionError: failHistory,
            },
        }));
    }
}
