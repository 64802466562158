import React from "react";
import "./table.scss";
import { Oval } from "react-loader-spinner";
interface TableColumnProps {
  style?: object;
  className?: string;
  title: string;
}

interface TableProp {
  column: TableColumnProps[];
  tableRows?: JSX.Element[];
  wrapClassName?: String;
  loading?: boolean;
  className?: String;
}

//Define how your cell of every row should be

export function Table(props: TableProp) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { column, tableRows, loading } = props;
  return (
    <>
      <div
        className={
          "cr-table_warpper " + (props.wrapClassName ? props.wrapClassName : "")
        }
      >
        <table
          className={"cr-table " + (props.className ? props.className : "")}
        >
          <thead>
            <tr>
              {column.map((data, key) => {
                const { className, style, title } = data;
                return (
                  <th key={key} style={style} className={className}>
                    {title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>{!loading && tableRows}</tbody>
        </table>
        {loading && (
          <Oval
            height="42"
            width="42"
            color="green"
            wrapperClass="flex-center mt24"
          />
        )}
      </div>
    </>
  );
}
