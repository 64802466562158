import { call, put } from 'redux-saga/effects';
import { alertPush, sendError } from '../../..';
import { API, RequestOptions } from '../../../../api';
import { getCsrfToken } from '../../../../helpers';
import {  SwapsSwapFetch, swapsSwapError } from '../actions';
 
const swapsOptions = (csrfToken?: string): RequestOptions => {
    return {
        apiVersion: 'app',
        headers: { 'X-CSRF-Token': csrfToken },
    };
};

export function* swapsSwapFetchSaga(action: SwapsSwapFetch) {
    try {
        // console.log(action.payload)
       const swaps = yield call(API.post(swapsOptions(getCsrfToken())), '/account/swaps', action.payload);
        // console.log(swaps)
        yield put(alertPush({ message: ['success.swap_request.created'], type: 'success' }));

        // yield put(swapsData(swaps));
    } catch (error) {
        // console.log(error) //this line will be remove .
        yield put(sendError({
            error,
            processingType: 'alert',
            extraOptions: {
                actionError: swapsSwapError,
            },
        }));
    }
}
