import React from "react";
import { LeftSideBar } from "../../containers";
import "./dashboard.scss";

interface Props {
  Component: () => JSX.Element;
}



export class DashboardPage extends React.Component<Props> {
  // constructor(props: Props) {
  //   super(props);

  //   // this.state = {
  //   //   otpCode: "",
  //   // };
  // }

  public render() {
    const { Component } = this.props
    return (
      <>
        <div className="pg-dashboard">

          <LeftSideBar />

          <div className="pg-main">
            {<Component />}
          </div>
        </div>


      </>
    );
  }


}