import { call, put } from 'redux-saga/effects';
import { alertPush, sendError } from '../../..';
import { API, RequestOptions } from '../../../../api';
import { getCsrfToken } from '../../../../helpers';
import { SwapsAction, SwapsFetch, swapsData, swapsError } from '../actions';
 
const swapsOptions = (csrfToken?: string): RequestOptions => {
    return {
        apiVersion: 'app',
        headers: { 'X-CSRF-Token': csrfToken },
    };
};

export function* swapsFetchSaga(action: SwapsFetch) {
    try {
        // console.log(action.payload)
       const swaps = yield call(API.get(swapsOptions(getCsrfToken())), '/account/swaps');
    //    console.log(swaps)
        yield put(swapsData(swaps));
    } catch (error) {
        // console.log(error) //this line will be remove .
        yield put(sendError({
            error,
            processingType: 'alert',
            extraOptions: {
                actionError: swapsError,
            },
        }));
    }
}
