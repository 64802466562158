import { call, put } from 'redux-saga/effects';
import {
     alertPush,
     signInRequire2FA} from '../../..';
import { API, RequestOptions } from '../../../../api';
// import { getCsrfToken } from '../../../../helpers';
// import { sendError } from '../../../public/errorHandler';
import { 
    // toggle2faData,
    //  toggle2faError, 
    // Toggle2FAFetch, toggleUser2fa, 
    Verify2FAFetch } from '../actions';

    const sessionsConfig: RequestOptions = {
        apiVersion: 'auth',
      };
      const csrfConfig: RequestOptions = {
        apiVersion: 'base',
      };

// const verify2faOptions = (csrfToken?: string): RequestOptions => {
//     return {
//         apiVersion: 'auth',
//         headers: { 'X-CSRF-Token': csrfToken },
//     };
// };

export function* verify2faSaga(action: Verify2FAFetch) {
    try {
        yield call(API.get(csrfConfig), '/sanctum/csrf-cookie');
        const data = yield call(API.post(sessionsConfig),"/g2fa-verify", action.payload);
        // console.log(data)
        if(data?.type === 'error'){
            yield put(alertPush({message: [data.text], type: 'error'}));
        }else{
            
            yield put(signInRequire2FA({ require2fa: false }));
        }
        // yield put(toggle2faData());
        // yield put(toggleUser2fa());
        // yield put(alertPush({message: [`success.otp.${enable ? 'enabled' : 'disabled'}`], type: 'success'}));
    } catch (error) { 
        // console.log(error)
        if(error?.type === 'error'){
            yield put(alertPush({message: [error.text], type: 'error'}));
        }
        // yield put(sendError({
        //     processingType: 'alert',
        //     error,
        //     extraOptions: {
        //         actionError: toggle2faError,
        //     },
        // }));
    }
}
