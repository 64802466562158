import * as React from "react";

interface CloseIconProps {
  className?: string;
  color?: string;
  onClick?: (e?: any) => void;
}

export const CloseIcon: React.FC<CloseIconProps> = (props: CloseIconProps) => {
  return (
    <div className={props.className}>
      <svg
        onClick={props.onClick}
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="CloseIcon"
      >
        <path
          fill={props.color ? props.color : "#4E4D7B"}
          d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        ></path>
      </svg>
    </div>
  );
};
