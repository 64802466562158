import { call, put } from 'redux-saga/effects';
import { alertPush, sendError } from '../../../';
import { API, RequestOptions } from '../../../../api';
import { getCsrfToken } from '../../../../helpers';
import { walletsWithdrawCcyData, walletsWithdrawCcyError, WalletsWithdrawCcyFetch } from '../actions';

const walletsWithdrawCcyOptions = (csrfToken?: string): RequestOptions => {
    return {
        apiVersion: 'app',
        headers: { 'X-CSRF-Token': csrfToken },
    };
};

export function* walletsWithdrawCcySaga(action: WalletsWithdrawCcyFetch) {
    try {
        // console.log(action.payload)
        yield call(API.post(walletsWithdrawCcyOptions(getCsrfToken())), '/account/withdraws', action.payload);
        yield put(walletsWithdrawCcyData());
        yield put(alertPush({ message: ['success.withdraw.action'], type: 'success' }));
    } catch (error) {
        console.log(error) //this line will be remove .
        yield put(sendError({
            error,
            processingType: 'alert',
            extraOptions: {
                actionError: walletsWithdrawCcyError,
            },
        }));
    }
}
