import React, { Component } from "react";
import "./taskcard.scss";
import { Badge } from "../Badge";
// import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";

type Props = {
  title: string;
  platform: string;
  availableTask: string;
  className?: string;
};

export class TaskCard extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const { className, title, platform, availableTask } = this.props;

    return (
      <>
        <div className={`card task-card ${className ? className : ""}`}>
          <div className="task-card_icon flex">
            <img src={`/img/${platform}.svg`} alt={platform} />
          </div>
          <div className="task-card_details">
            <div className="task-card_title">{title}</div>
            <div className="task-card_body">Available Task: <Badge type="success" lable={availableTask} /></div>
          </div>

        </div>

      </>
    );
  }
}
