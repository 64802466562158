import * as React from "react";

interface DollarIconProps {
  className?: string;
}

export const DollarIcon: React.FC<DollarIconProps> = (props: DollarIconProps) => {
  return (

    <>
 
      <svg  className={props.className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10 4C10.5523 4 11 4.44772 11 5V5.09236C11.9405 5.26915 12.7915 5.69637 13.354 6.34462C13.7159 6.76176 13.6712 7.39335 13.254 7.7553C12.8369 8.11726 12.2053 8.07252 11.8434 7.65538C11.6808 7.4681 11.391 7.27771 11 7.15075L11 9.09199C11.6216 9.20873 12.1965 9.43407 12.676 9.75373C13.3977 10.2348 14 11.0093 14 12C14 12.9907 13.3977 13.7651 12.676 14.2463C12.1965 14.5659 11.6216 14.7913 11 14.908L11 15C11 15.5523 10.5523 16 9.99998 16C9.44769 16 8.99999 15.5523 9 15L9 14.9076C8.05956 14.7308 7.20855 14.3036 6.64607 13.6554C6.28411 13.2382 6.32885 12.6067 6.74599 12.2447C7.16313 11.8827 7.79471 11.9275 8.15667 12.3446C8.31917 12.5319 8.60902 12.7223 9.00002 12.8492L9.00001 10.908C8.37841 10.7913 7.80348 10.5659 7.32398 10.2463C6.6023 9.76515 6 8.99067 6 8C6 7.00933 6.6023 6.23485 7.32398 5.75374C7.80348 5.43407 8.3784 5.20873 9 5.09199V5C9 4.44772 9.44771 4 10 4ZM9 7.15101C8.77939 7.2224 8.58818 7.31464 8.43338 7.41784C8.06927 7.66058 8 7.8861 8 8C8 8.1139 8.06927 8.33942 8.43338 8.58216C8.58818 8.68536 8.7794 8.7776 9.00001 8.84899L9 7.15101ZM11 11.151L11 12.849C11.2206 12.7776 11.4118 12.6854 11.5666 12.5822C11.9308 12.3394 12 12.1139 12 12C12 11.8861 11.9308 11.6606 11.5666 11.4178C11.4118 11.3146 11.2206 11.2224 11 11.151Z" fill="var(--icon)"/>
</svg>




    </>
  );
};
