import { 
    call,
     put 
    } from 'redux-saga/effects';
// import { sendError } from '../../../';
import { API, RequestOptions } from '../../../../api';
// import { sendError } from '../../../public/errorHandler';
// import { changeLanguage } from '../../../public/i18n';
// import { userData } from '../../profile';
import {
    // signInData,
    // signInError,
    // SignInFetch,
    SignUpFetch, signUpError, signUpRequireVerification,
    // signInRequire2FA,
    // signUpRequireVerification,
} from '../actions';
import { alertPush } from '../../../public/alert';
// import { userData } from '../../profile';
// import { userData } from '../../profile';

const sessionsConfig: RequestOptions = {
    apiVersion: 'auth',
};
// const csrfConfig: RequestOptions = {
//     apiVersion: 'base',
// };

export function* signUpSaga(action: SignUpFetch) {
    try {
 
        yield call(API.post(sessionsConfig), '/identity/users', action.payload);
        yield put(signUpRequireVerification({ requireVerification: true })); 

        yield put(alertPush({ type: 'success', message: ["Successfully Registered"]}))
    } catch (error) {
 
          yield put(signUpError(error));
          yield put(alertPush({message: error.message, code: error.code, type: 'error'}));
 
    }
}
