import { CommonState } from '../../types';
import { TransactionsAction } from './actions';
import {
    TRANSACTIONS_DATA,
    TRANSACTIONS_ERROR,
    TRANSACTIONS_FETCH,
} from './constants';
import { Transaction } from './types';

export interface TransactionsState extends CommonState {
    list: Transaction[];
    loading: boolean;
}

export const initialTransactionsState: TransactionsState = {
    list: [],
    loading: false,
};

export const transactionsReducer = (state = initialTransactionsState, action: TransactionsAction) => {
    switch (action.type) {
        case TRANSACTIONS_FETCH:
            return {
                ...state,
                loading: true,
            };
        case TRANSACTIONS_DATA:
            return {
                ...state,
                loading: false,
                list: action.payload,
            };
        case TRANSACTIONS_ERROR:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};