import React, { Component } from "react";
import "./summarycard.scss";
// import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";

type Props = {
  title: string;
  details: string;
  desc: string;
  bgColor?: string;
  className?: string;
};

export class SummaryCard extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const { bgColor, desc, className, title, details } = this.props;

    return (
      <>
        <div className={`card summary-card ${className ? className : ""}`} style={{ backgroundColor: bgColor ? bgColor : "#4A6ABA" }}>
          <div className="summary-card_title">{title}</div>
          <div className="summary-card_details">{details}</div>
          <div className="summary-card_description">{desc}</div>
        </div>

      </>
    );
  }
}

// Enter Full Name
