import { combineReducers } from 'redux';
import { alertReducer } from './public/alert';
import { errorHandlerReducer } from './public/errorHandler';
import { authReducer } from './user/auth';
import { profileReducer } from './user/profile';
import { sendEmailVerificationReducer } from './user/emailVerification';
import { passwordReducer } from './user/password';
import { changeLanguageReducer } from './public/i18n';
import { configsReducer } from './public/configs';
import { getGeetestCaptchaReducer } from './user/captcha';
import { currenciesReducer } from './public/currencies';
import { walletsReducer } from './user/wallets';
import { transactionsReducer } from './user/transactions';
import { historyReducer } from './user/history';
import { swapsReducer } from './user/swaps';
import { couponsCheckReducer } from './user/coupons';

export const publicReducer = combineReducers({
    alerts: alertReducer,
    errorHandler: errorHandlerReducer,
    i18n: changeLanguageReducer,
    configs: configsReducer,
    currencies: currenciesReducer,

});

export const userReducer = combineReducers({
    auth: authReducer,
    password: passwordReducer,
    profile: profileReducer,
    sendEmailVerification: sendEmailVerificationReducer,
    captcha: getGeetestCaptchaReducer,
    wallets: walletsReducer,
    transactions: transactionsReducer,
    history: historyReducer,
    swaps: swapsReducer,
    coupons: couponsCheckReducer,

});
