import * as React from "react";
import "./register.scss"
//import ReCAPTCHA from 'react-google-recaptcha'
import { injectIntl } from "react-intl";

// import { captcha, captchaLogin } from '../../api';

import { History } from "history";
import {
  connect,
  MapDispatchToPropsFunction,
  MapStateToProps,
} from "react-redux";

import { compose } from "redux";
import { IntlProps } from "../..";
import { Captcha, SignUpForm } from "../../components";
import {
  EMAIL_REGEX,
  ERROR_INVALID_EMAIL,
  ERROR_INVALID_PASSWORD,
  ERROR_PASSWORD_CONFIRMATION,
  PASSWORD_REGEX,
  passwordErrorFirstSolution,
  passwordErrorSecondSolution,
  passwordErrorThirdSolution,
  USERNAME_REGEX,
} from "../../helpers";
import {
  Configs,
  entropyPasswordFetch,
  GeetestCaptchaResponse,
  LanguageState,
  resetCaptchaState,
  RootState,
  selectCaptchaResponse,
  selectConfigs,
  selectCurrentLanguage,
  selectCurrentPasswordEntropy,
  selectGeetestCaptchaSuccess,
  selectRecaptchaSuccess,
  selectSignUpError,
  selectSignUpRequireVerification,
  signUp,
} from "../../modules";
import { isUsernameEnabled } from "../../api";

//eslint-disable-next-line

interface ReduxProps {
  configs: Configs;
  requireVerification?: boolean;
  loading?: boolean;
  currentPasswordEntropy: number;
  captcha_response?: string | GeetestCaptchaResponse;
  reCaptchaSuccess: boolean;
  geetestCaptchaSuccess: boolean;
}

interface DispatchProps {
  signUp: typeof signUp;
  fetchCurrentPasswordEntropy: typeof entropyPasswordFetch;
  resetCaptchaState: typeof resetCaptchaState;
}

interface RouterProps {
  location: {
    search: string;
  };
  history: History;
}
interface OwnProps {
  signUpError: boolean;
  i18n: LanguageState["lang"];
}

type Props = ReduxProps & DispatchProps & RouterProps & IntlProps & OwnProps;

export const extractRefID = (props: RouterProps) => new URLSearchParams(props.location.search).get('ref');


export class SignUpPageC extends React.Component<Props> {
  //    private reCaptchaRef: any;

  public readonly state = {
    email: "",
    password: "",
    confirmPassword: "",
    // reCaptchaSuccess: false,
    // captcha_response: '',
    showModal: false,
    username: "",
    usernameError: "",
    refId: "",
    hasConfirmed: false,
    emailError: "",
    passwordError: "",
    confirmationError: "",
    usernameFocused: false,
    emailFocused: false,
    passwordFocused: false,
    confirmPasswordFocused: false,
    refIdFocused: false,
    typingTimeout: 0,
    passwordErrorFirstSolved: false,
    passwordErrorSecondSolved: false,
    passwordErrorThirdSolved: false,
    passwordPopUp: false,
  };

  private myRef = React.createRef<HTMLInputElement>();
  // private passwordWrapper = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    const localReferralCode = localStorage.getItem("referralCode");
    const refId = this.extractRefID(this.props.location.search);

    const referralCode = refId || localReferralCode || "";
    this.setState({
      refId: referralCode,
    });
    if (refId && refId !== localReferralCode) {
      localStorage.setItem("referralCode", referralCode);
    }


    // document.addEventListener('click', this.handleOutsideClick, false);
  }

  public componentDidUpdate(prev: Props) {
    const { email } = this.state;

    if (!prev.requireVerification && this.props.requireVerification) {
      this.props.history.push("/verify-email", { email: email });
    }
  }

  // public componentWillUnmount() {
  //     document.removeEventListener('click', this.handleOutsideClick, false);
  // }

  // private handleReCaptchaSuccess = (value: string | null): void => {
  //         this.setState({
  //                 reCaptchaSuccess: true,
  //                 captcha_response: value,
  //         });
  //         console.log(value)
  // };

  public render() {
    const {
      configs,
      loading,
      currentPasswordEntropy,
      // captcha_response,
      // reCaptchaSuccess,
      // geetestCaptchaSuccess,
    } = this.props;
    const {
      username,
      usernameError,
      email,
      password,
      confirmPassword,
      refId,
      hasConfirmed,
      emailError,
      passwordError,
      confirmationError,
      // usernameFocused,
      emailFocused,
      passwordFocused,
      confirmPasswordFocused,
      refIdFocused,
      passwordErrorFirstSolved,
      passwordErrorSecondSolved,
      passwordErrorThirdSolved,
      passwordPopUp,
    } = this.state;

    return (
      <>
        <SignUpForm
          labelSignIn={this.props.intl.formatMessage({
            id: "page.header.signIn",
          })}
          labelSignUp={this.props.intl.formatMessage({
            id: "page.header.signUp",
          })}
          emailLabel={this.props.intl.formatMessage({
            id: "page.header.signUp.email",
          })}
          passwordLabel={this.props.intl.formatMessage({
            id: "page.header.signUp.password",
          })}
          confirmPasswordLabel={this.props.intl.formatMessage({
            id: "page.header.signUp.confirmPassword",
          })}
          referalCodeLabel={this.props.intl.formatMessage({
            id: "page.header.signUp.referalCode",
          })}
          termsMessage={this.props.intl.formatMessage({
            id: "page.header.signUp.terms",
          })}
          refId={refId}
          handleChangeRefId={this.handleChangeRefId}
          isLoading={loading}
          onSignIn={this.handleSignIn}
          onSignUp={this.handleSignUp}
          username={username}
          usernameError={usernameError}
          handleChangeUsername={this.handleChangeUsername}
          email={email}
          handleChangeEmail={this.handleChangeEmail}
          password={password}
          handleChangePassword={this.handleChangePassword}
          confirmPassword={confirmPassword}
          handleChangeConfirmPassword={this.handleChangeConfirmPassword}
          hasConfirmed={hasConfirmed}
          clickCheckBox={this.handleCheckboxClick}
          validateForm={this.handleValidateForm}
          emailError={emailError}
          passwordError={passwordError}
          confirmationError={confirmationError}
          confirmPasswordFocused={confirmPasswordFocused}
          refIdFocused={refIdFocused}
          // usernameFocused={usernameFocused}
          emailFocused={emailFocused}
          passwordFocused={passwordFocused}
          handleFocusUsername={this.handleFocusUsername}
          handleFocusEmail={this.handleFocusEmail}
          handleFocusPassword={this.handleFocusPassword}
          handleFocusConfirmPassword={this.handleFocusConfirmPassword}
          handleFocusRefId={this.handleFocusRefId}
          captchaType={configs.captcha_type}
          renderCaptcha={this.renderCaptcha()}
          reCaptchaSuccess={this.props.reCaptchaSuccess}
          geetestCaptchaSuccess={this.props.geetestCaptchaSuccess}
          captcha_response={this.props.captcha_response}
          currentPasswordEntropy={currentPasswordEntropy}
          minPasswordEntropy={14}
          passwordErrorFirstSolved={passwordErrorFirstSolved}
          passwordErrorSecondSolved={passwordErrorSecondSolved}
          passwordErrorThirdSolved={passwordErrorThirdSolved}
          passwordPopUp={passwordPopUp}
          myRef={this.myRef}

        // passwordWrapper={this.passwordWrapper}
        // translate={this.translate}
        />
      </>
    );
  }

  public renderCaptcha = () => {
    const { signUpError } = this.props;
    const { confirmationError, emailError } = this.state;

    const error = signUpError || confirmationError || emailError;

    return <Captcha error={error} />;
  };

  private handleChangeUsername = (value: string) => {
    this.setState({
      username: value.replace(/[^A-Za-z0-9]+/g, "").toLowerCase(),
    });
  };
  private extractRefID = (url: string) => new URLSearchParams(url).get("ref");
  private handleFocusUsername = () => {
    this.setState({
      usernameFocused: !this.state.usernameFocused,
    });
  };
  private handleFocusRefId = () => {
    this.setState({
      refIdFocused: !this.state.refIdFocused,
    });
  };
  private handleFocusEmail = () => {
    this.setState({
      emailFocused: !this.state.emailFocused,
    });
  };

  private handleFocusPassword = () => {
    this.setState({
      passwordFocused: !this.state.passwordFocused,
      passwordPopUp: !this.state.passwordPopUp,
    });
  };

  private handleFocusConfirmPassword = () => {
    this.setState({
      confirmPasswordFocused: !this.state.confirmPasswordFocused,
      passwordPopUp: false,
    });
  };

  private handleChangeEmail = (value: string) => {
    this.setState({
      email: value,
    });
  };

  private handleChangePassword = (value: string) => {
    const {
      passwordErrorFirstSolved,
      passwordErrorSecondSolved,
      passwordErrorThirdSolved,
    } = this.state;

    if (passwordErrorFirstSolution(value) && !passwordErrorFirstSolved) {
      this.setState({
        passwordErrorFirstSolved: true,
      });
    } else if (!passwordErrorFirstSolution(value) && passwordErrorFirstSolved) {
      this.setState({
        passwordErrorFirstSolved: false,
      });
    }

    if (passwordErrorSecondSolution(value) && !passwordErrorSecondSolved) {
      this.setState({
        passwordErrorSecondSolved: true,
      });
    } else if (
      !passwordErrorSecondSolution(value) &&
      passwordErrorSecondSolved
    ) {
      this.setState({
        passwordErrorSecondSolved: false,
      });
    }

    if (passwordErrorThirdSolution(value) && !passwordErrorThirdSolved) {
      this.setState({
        passwordErrorThirdSolved: true,
      });
    } else if (!passwordErrorThirdSolution(value) && passwordErrorThirdSolved) {
      this.setState({
        passwordErrorThirdSolved: false,
      });
    }

    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      password: value,
      typingTimeout: setTimeout(() => {
        this.props.fetchCurrentPasswordEntropy({ password: value });
      }, 500),
    });
  };

  private handleChangeConfirmPassword = (value: string) => {
    this.setState({
      confirmPassword: value,
    });
  };

  private handleChangeRefId = (value: string) => {
    this.setState({
      refId: value,
    });
  };

  private handleSignIn = () => {
    this.props.history.push("/signin");
  };

  private handleValidateForm = () => {
    const { email, password, confirmPassword, username } = this.state;
    const isEmailValid = email.match(EMAIL_REGEX);
    const isPasswordValid = password.match(PASSWORD_REGEX);
    const isConfirmPasswordValid = password === confirmPassword;
    const isUsernameValid = username.match(USERNAME_REGEX);

    if (!isEmailValid && !isPasswordValid) {
      this.setState({
        confirmationError: "",
        emailError: this.props.intl.formatMessage({ id: ERROR_INVALID_EMAIL }),
        passwordError: this.props.intl.formatMessage({
          id: ERROR_INVALID_PASSWORD,
        }),
        hasConfirmed: false,
      });

      return;
    }

    if (!isEmailValid) {
      this.setState({
        confirmationError: "",
        emailError: this.props.intl.formatMessage({ id: ERROR_INVALID_EMAIL }),
        passwordError: "",
        hasConfirmed: false,
      });

      return;
    }

    if (!isPasswordValid) {
      this.setState({
        confirmationError: "",
        emailError: "",
        passwordError: this.props.intl.formatMessage({
          id: ERROR_INVALID_PASSWORD,
        }),
        hasConfirmed: false,
      });

      return;
    }

    if (isUsernameEnabled() && !isUsernameValid) {
      this.setState({ usernameError: "Invalid Username" });
    }

    if (!isConfirmPasswordValid) {
      this.setState({
        confirmationError: this.props.intl.formatMessage({
          id: ERROR_PASSWORD_CONFIRMATION,
        }),
        emailError: "",
        passwordError: "",
        hasConfirmed: false,
      });

      return;
    }
  };
  private handleSignUp = () => {
    const { configs, i18n, captcha_response } = this.props;
    const { username, email, password, refId } = this.state;
    let payload: any = {
      email,
      password,
      data: JSON.stringify({
        language: i18n,
      }),
    };

    if (isUsernameEnabled()) {
      payload = { ...payload, username };
    }

    if (refId) {
      payload = { ...payload, refid: refId };
    }

    switch (configs.captcha_type) {
      case "recaptcha":
      case "geetest":
        payload = { ...payload, captcha_response };

        console.log(payload);
        this.props.signUp(payload);
        break;
      default:
        this.props.signUp(payload);
        break;
    }

    this.props.resetCaptchaState();
  };

  //
  private handleCheckboxClick = event => {
    if (event) {
      event.preventDefault();

      this.setState({
        hasConfirmed: !this.state.hasConfirmed,
      });
    }
  };
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, RootState> = (
  state
) => ({
  configs: selectConfigs(state),
  i18n: selectCurrentLanguage(state),
  requireVerification: selectSignUpRequireVerification(state),
  signUpError: selectSignUpError(state),
  currentPasswordEntropy: selectCurrentPasswordEntropy(state),
  captcha_response: selectCaptchaResponse(state),
  reCaptchaSuccess: selectRecaptchaSuccess(state),
  geetestCaptchaSuccess: selectGeetestCaptchaSuccess(state),
});
const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, {}> = (
  dispatch
) => ({
  signUp: (credentials) => dispatch(signUp(credentials)),
  fetchCurrentPasswordEntropy: (payload) =>
    dispatch(entropyPasswordFetch(payload)),
  resetCaptchaState: () => dispatch(resetCaptchaState()),
});

// tslint:disable no-any

export const RegisterPage = compose(
  injectIntl,
  // withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SignUpPageC) as React.ComponentClass;
