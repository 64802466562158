import React, { Component } from "react";
import "./alert.scss";

type Props = {
  variant?: string;
  className?: string;
  message?: string;
  children: string;
};

interface State {}
export class Alert extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const { variant, className, children } = this.props;

    return (
      <>
        <span
          className={`alert ${className ? className : ""} alert-${variant}`}
        >
          {children}
        </span>
      </>
    );
  }
}

// Enter Full Name
