export const SWAPS_FETCH = 'swaps/FETCH';
export const SWAPS_DATA = 'swaps/DATA';
export const SWAPS_ERROR = 'swaps/ERROR';

export const SWAPS_RESET = 'swaps/RESET';

export const SWAPS_SWAP_FETCH = 'swaps/SWAP_FETCH';
export const SWAPS_SWAP_DATA = 'swaps/SWAP_DATA';
export const SWAPS_SWAP_ERROR = 'swaps/SWAP_ERROR';


