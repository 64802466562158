import React, { Component } from "react";
import "./badge.scss";

// import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";

type Props = {
  type: "primary" | "secondary" | "success" | "danger" | "warning" | "default";
  lable?: string;
  className?: string;
};

export class Badge extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      lable,
      type,

      className,
    } = this.props;

    return (
      <>
        <div
          className={`badge badge-${type} ${className ? className : ""
            } `}
        >

          {lable ? lable : ""}

        </div>
      </>
    );
  }
}

// Enter Full Name
