import { call, put } from 'redux-saga/effects';
import { sendError } from '../../..';
import { API, RequestOptions } from '../../../../api';
import { getCsrfToken } from '../../../../helpers';
import { userGroupData, userGroupError, UserGroupFetch } from '../actions';

const userGroupDataOptions = (csrfToken?: string): RequestOptions => {
    return {
        apiVersion: 'app',
        headers: { 'X-CSRF-Token': csrfToken },
    };
};

export function* groupFetchSaga(action: UserGroupFetch) {
    try {
        const data = yield call(API.get(userGroupDataOptions(getCsrfToken())), '/account/group');
        yield put(userGroupData({ group: data.group }));
    } catch (error) {
        yield put(sendError({
            error,
            processingType: 'alert',
            extraOptions: {
                actionError: userGroupError,
            },
        }));
    }
}
 