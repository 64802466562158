import React from "react";
import "./referrals.scss";
import { Input, Status, Table, copy } from "../../components";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { alertPush, selectUserInfo } from "../../modules";
import { capitalize } from "../../helpers";
import { URLSchema, myURL } from "../../api";

export const Referrals = () => {

  const user = useSelector(selectUserInfo);
  const dispatch = useDispatch();
  const copyAddress = () => {
    copy("referral-address");
    const payload = { message: ["Address Copied"], type: "success" };
    dispatch(alertPush({ ...payload }));
  };


  const Statuses = {
    completed: "success",
    processing: "warning",
  };
  const Column = [
    { title: "Date", style: { minWidth: 170 } },
    { title: "Ref. Account" },
    { title: "Earned Points" },
    { title: "Status" },
  ];
  const EarnedHistories = [
    // {
    //   id: 1,
    //   date: "2023-03-26 20:14:35",
    //   ref_account: "UID124523225",
    //   type: "comission",
    //   points: "10",
    //   status: "completed"
    // },
    // {
    //   id: 2,
    //   date: "2023-03-26 20:14:35",
    //   ref_account: "UID124523225",
    //   type: "comission",
    //   points: "10",
    //   status: "completed"
    // },
    // {
    //   id: 3,
    //   date: "2023-03-26 20:14:35",
    //   ref_account: "UID124523225",
    //   type: "comission",
    //   points: "10",
    //   status: "completed"
    // },
    // {
    //   id: 4,
    //   date: "2023-03-26 20:14:35",
    //   ref_account: "UID124523225",
    //   type: "comission",
    //   points: "10",
    //   status: "completed"
    // },
    // {
    //   id: 5,
    //   date: "2023-03-26 20:14:35",
    //   ref_account: "UID124523225",
    //   type: "comission",
    //   points: "10",
    //   status: "completed"
    // },

  ];

  const renderCells = (histories: any[]) =>
    histories.map((history, index) => {
      const { date, status, ref_account, points } = history;
      return (
        <tr key={index}>
          <td>{date}</td>
          <td>{ref_account}</td>
          <td>{points}</td>
          <td>
            <Status type={Statuses[status]} label={capitalize(status)} />
          </td>
        </tr>
      );
    });

  return (
    <>
      <div className=" container cx-center  referrals bg-trans ">
        <div className="row referrals-wrapper">
          {/* <h3>Referrals</h3> */}

          <div className="referrals-content">
            <div className="referrals-content_left">
              <h2>Invite Friends <br /> Earn Crypto Together </h2>
              <p>Earn up to 15% commission every time your
                friends make an exchange</p>

              <div className="referrals-content_left_item_wrapper">
                <div className="referrals-content_left_item">
                  <div className="referrals-content_left_item_icon">
                    <img src="/img/commission.svg" alt="Commissions" />
                  </div>
                  <div className="referrals-content_left_item_details">
                    <span>Your commissions</span>
                    <span>5 %</span>

                  </div>
                </div>
                <div className="referrals-content_left_item">
                  <div className="referrals-content_left_item_icon">
                    <img src="/img/earned.svg" alt="Earned" />
                  </div>
                  <div className="referrals-content_left_item_details">
                    <span>Earned Points</span>
                    <span>0</span>

                  </div>

                </div>
                <div className="referrals-content_left_item">
                  <div className="referrals-content_left_item_icon">
                    <img src="/img/referral.svg" alt="Referrals" />
                  </div>
                  <div className="referrals-content_left_item_details">
                    <span>Number Of Referrals</span>
                    <span>{user.referrals}</span>

                  </div>

                </div>
                <div className="referrals-content_left_item">
                  <div className="referrals-content_left_item_icon">
                    <img src="/img/active_ref.svg" alt="Active Ref" />
                  </div>
                  <div className="referrals-content_left_item_details">
                    <span>Active Referrals</span>
                    <span>{user.referrals}</span>

                  </div>
                </div>
              </div>
            </div>

            <div className="referrals-content_right">
              <h2>Referral Levels</h2>
              <div className="referrals-content_right_item_wrapper">
                <div className="referrals-content_right_item">
                  <div className="referrals-content_right_item_icon">
                    <img src="/img/level1.svg" alt="Commissions" />
                  </div>
                  <div className="referrals-content_right_item_details">
                    <span>Level 1</span>
                    <span>Less than 1000 active users,
                      you will earn 5% commissions.</span>

                  </div>


                </div>

                <div className="referrals-content_right_item">
                  <div className="referrals-content_right_item_icon">
                    <img src="/img/level2.svg" alt="Commissions" />
                  </div>
                  <div className="referrals-content_right_item_details">
                    <span>Level 2</span>
                    <span>Between 1000 to 10000 active users,
                      you will earn 10% commissions.</span>

                  </div>


                </div>

                <div className="referrals-content_right_item">
                  <div className="referrals-content_right_item_icon">
                    <img src="/img/level3.svg" alt="Commissions" />
                  </div>
                  <div className="referrals-content_right_item_details">
                    <span>Level 3</span>
                    <span>More than 10000 active users,
                      you will earn 15% commissions.</span>

                  </div>


                </div>


              </div>
            </div>

          </div>

          {/* Referral link */}
          <div className="referrals_link">
            <h3>Referral Link</h3>
            <Input
              value={`${URLSchema}://${myURL}/signup?ref=${user.uid}`}
              id="referral-address"
              rightAddon={<DocumentDuplicateIcon onClick={copyAddress} />}
            />

          </div>


          {/* Referral Commission Payout */}
          <div className="refferals-commission_payouts">
            <h2>Commision Payout</h2>
            <p>Recently 5 Payouts</p>
            <Table column={Column} tableRows={renderCells(EarnedHistories)} />

          </div>



        </div>
      </div>

    </>
  );
};
