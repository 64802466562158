import { call, put } from 'redux-saga/effects';
import { sendError } from '../../..';
import { API, RequestOptions } from '../../../../api';
import { CouponsCheckValidFetch, couponsCheckValidData  } from '../actions';

const couponCheckOptions: RequestOptions = {
    apiVersion: 'app',
};

export function* couponValidCheckSaga(action: CouponsCheckValidFetch) {
    try {
        const response = yield call(API.post(couponCheckOptions), '/account/check-presale-coupon', action.payload);
        // console.log('check coupon:',response)
        yield put(couponsCheckValidData(response));
    } catch (error) {
    //    console.log(error)
    }
}
