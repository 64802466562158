import React, { Component } from "react";
import "./addon.scss";

type Props = {
  content: React.ReactNode;
  className?: string;
};

export class Addon extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const { content, className } = this.props;

    return (
      <>
        <div className={"input-addon " + (className ? className : "")}>
          {content}
        </div>
      </>
    );
  }
}

// Enter Full Name
