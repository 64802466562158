import * as React from "react";

interface WalletIconProps {
  className?: string;
}

export const WalletIcon: React.FC<WalletIconProps> = (props: WalletIconProps) => {
  return (

    <>

      <svg className={props.className} width="20" height="18" viewBox="0 0 20 18" fill="var(--icon)" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.9474 4.54222V2.38889C18.9474 1.35 18 0.5 16.8421 0.5H2.10526C0.936842 0.5 0 1.35 0 2.38889V15.6111C0 16.65 0.936842 17.5 2.10526 17.5H16.8421C18 17.5 18.9474 16.65 18.9474 15.6111V13.4578C19.5684 13.1272 20 12.5322 20 11.8333V6.16667C20 5.46778 19.5684 4.87278 18.9474 4.54222ZM17.8947 6.16667V11.8333H10.5263V6.16667H17.8947ZM2.10526 15.6111V2.38889H16.8421V4.27778H10.5263C9.36842 4.27778 8.42105 5.12778 8.42105 6.16667V11.8333C8.42105 12.8722 9.36842 13.7222 10.5263 13.7222H16.8421V15.6111H2.10526Z" fill="var(--icon)" fillOpacity="0.866667" />
      </svg>

    </>
  );
};
