import { CommonError } from '../../types';
import { COUPONS_CHECKBOX, COUPONS_CHECK_CLEAR, COUPONS_CHECK_VALID_DATA, COUPONS_CHECK_VALID_ERROR, COUPONS_CHECK_VALID_FETCH } from './constants';
 
export interface CouponsCheckValidFetch {
    type: typeof COUPONS_CHECK_VALID_FETCH;
    payload: {
     discount_code: string;
     swap_pair: string
    };
  }
  
  export interface CouponsCheckValidData {
    type: typeof COUPONS_CHECK_VALID_DATA;
    payload: {
      swap_id: string;
      swap_pair: string;
      discount_code: string;
      discount_percent: number;
      start_time: string;
      end_time: string;
      status: string;
    };
  }
  
  export interface CouponsCheckValidError {
    type: typeof COUPONS_CHECK_VALID_ERROR;
  }

  export interface CouponCheckClear{
    type: typeof COUPONS_CHECK_CLEAR;
  }

  export interface CouponCheckbox{
    type: typeof COUPONS_CHECKBOX;
    payload: boolean;
  
  }
export type CouponsCheckAction =  CouponsCheckValidFetch
| CouponsCheckValidData
| CouponCheckClear
| CouponCheckbox
| CouponsCheckValidError;

 


export const couponsCheckValidFetch = (payload: CouponsCheckValidFetch['payload']): CouponsCheckValidFetch => ({
    type: COUPONS_CHECK_VALID_FETCH,
    payload,
  });
  
  export const couponsCheckValidData = (payload: CouponsCheckValidData['payload']): CouponsCheckValidData => ({
    type: COUPONS_CHECK_VALID_DATA,
    payload,
  });
  
  export const couponsCheckValidError = (): CouponsCheckValidError => ({
    type: COUPONS_CHECK_VALID_ERROR,
  });

  export const couponCheckClear = (): CouponCheckClear => ({
    type: COUPONS_CHECK_CLEAR,
  });

  export const couponCheckbox = (payload: boolean): CouponCheckbox => ({
    type: COUPONS_CHECKBOX,
    payload,
  });