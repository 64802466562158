import React from "react";
import { Route, Switch } from "react-router";
import { Redirect, withRouter } from "react-router-dom";
import {
  LoginPage,
  RegisterPage,
  ForgotPasswordPage,
  DashboardPage,
  ResetPasswordPage,
  // Google2FAPage,
  EmailConfirmation,
  // LandingPage,
  TermsPage,
  PrivacyPage,
  // ProfilePage,
} from "../pages";
import {
  RootState,
  User,
  configsFetch,
  logoutFetch,
  selectEmailVerified,
  selectSignInRequire2FA,
  selectUserFetching,
  selectUserInfo,
  selectUserLoggedIn,
  userFetch,
} from "../modules";
import { MapDispatchToProps, MapStateToProps, connect } from "react-redux";
import { minutesUntilAutoLogout } from "../api";
import { CouponSalesHistory, Dashboard, DepositHistory, Exchange, MyTask, Profile, Referrals, SwapHistory, Task, Wallet, WalletsFetch, WatchYoutube, WithdrawHistory } from "../containers";
import { EmailVerificationPage } from "../pages/EmailVerification";
import { ExpiredSessionModal } from "../components";
import { GoogleAuthenticatorScreen } from "../pages/TwoFactorAuthScreen";

interface ReduxProps {
  // colorTheme: string;
  user: User;
  isLoggedIn: boolean;
  userLoading?: boolean;
  require2FA?: boolean;
  emailVerified?: boolean;
  // rangerState: RangerState;
}

interface DispatchProps {
  fetchConfigs: typeof configsFetch;
  // currenciesFetch: typeof currenciesFetch;
  logout: typeof logoutFetch;
  userFetch: typeof userFetch;
  // walletsReset: typeof walletsReset;
  // rangerConnect: typeof rangerConnectFetch;
}

const CHECK_INTERVAL = 15000;
const STORE_KEY = "lastAction";

interface OwnProps {
  history: History;
}

interface LayoutState {
  isShownExpSessionModal: boolean;
}

export type LayoutProps = ReduxProps & DispatchProps & OwnProps;
// IntlProps;

// const renderLoader = () => <PageLoader />;

//tslint:disable-next-line no-any
const PrivateRoute: React.FunctionComponent<any> = ({
  component: CustomComponent,
  loading,
  isLogged,
  // emailVerified,
  // require2FA,
  ...rest
}) => {
  if (loading) {
    return <span>Loading...</span>;
    // return renderLoader();
  }
  const renderCustomerComponent = (props) => <CustomComponent {...props} />;

  // if (emailVerified) {
  //   return <Redirect to={"/verify-email"} />;
  // }

  // if (require2FA) {
  //   return <Redirect to={"/verify-2fa"} />;
  // }

  if (isLogged) {
    return <Route {...rest} render={renderCustomerComponent} />;
  }

  return (
    <Route {...rest}>
      <Redirect to={"/signin"} />
    </Route>
  );
};

//tslint:disable-next-line no-any
const PublicRoute: React.FunctionComponent<any> = ({
  component: CustomComponent,
  loading,
  isLogged,
  ...rest
}) => {
  if (loading) {
    // return renderLoader();
    return <span>Loading...</span>;
  }

  if (isLogged) {
    return (
      <Route {...rest}>
        <Redirect to={"/wallet"} />
      </Route>
    );
  }

  const renderCustomerComponent = (props) => <CustomComponent {...props} />;

  return <Route {...rest} render={renderCustomerComponent} />;
};

class LayoutComponent extends React.Component<LayoutProps, LayoutState> {
  public static eventsListen = [
    "click",
    "keydown",
    "scroll",
    "resize",
    "mousemove",
    "TabSelect",
    "TabHide",
  ];

  public timer;
  // public walletsFetchInterval;

  constructor(props: LayoutProps) {
    super(props);
    this.initListener();

    this.state = {
      isShownExpSessionModal: false,
    };
  }

  public componentDidMount() {
    // const {
    //   // isLoggedIn,
    //   user,
    // } = this.props;

    const token = localStorage.getItem("csrfToken");

    if (token) {
      this.props.userFetch();
      this.initInterval();
      this.check();
    }

    this.props.fetchConfigs();

    // if (!currencies.length) {
    //   this.props.currenciesFetch();
    // }
  }

  public componentDidUpdate(next: LayoutProps) {
    // const {
    //   // require2FA,
    //   // isLoggedIn,
    // } = this.props;
    // if (!isLoggedIn && next.isLoggedIn) {
    //   this.props.walletsReset();
    // }
  }

  public componentWillReceiveProps(nextProps: LayoutProps) {
    if (!this.props.user.email && nextProps.user.email) {
      this.props.userFetch(); //uncomment
    }
  }
  public componentWillUnmount() {
    for (const type of LayoutComponent.eventsListen) {
      document.body.removeEventListener(type, this.reset);
    }
    clearInterval(this.timer);
    // clearInterval(this.walletsFetchInterval);
  }

  // public translate = (key: string) =>
  //   this.props.intl.formatMessage({ id: key });

  public render() {
    const {
      //   colorTheme,
      isLoggedIn,
      userLoading,
      // require2FA,
      // emailVerified,
    } = this.props;

    const { isShownExpSessionModal } = this.state;
    return (
      <>
        <Switch>
          {/* Universal Route */}

          {/* Public Route */}

          <PublicRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            exact={true}
            path="/terms"
            component={TermsPage}
          />

          <PublicRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            exact={true}
            path="/privacy"
            component={PrivacyPage}
          />

          <PublicRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            exact={true}
            path="/"
            component={LoginPage}
          />

          <PublicRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            path="/login"
            component={LoginPage}
          />
          <PublicRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            path="/forgot-password"
            component={ForgotPasswordPage}
          />
          <PublicRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            path="/signup"
            component={RegisterPage}
          />
          <PublicRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            path="/accounts/password_reset"
            component={ResetPasswordPage}
          />

          {/* Private Routes */}
          {/* Private Routes Only */}

          <PublicRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            exact={true}
            path="/verify-email"
          >
            <EmailVerificationPage />
          </PublicRoute>

          <PublicRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            path="/accounts/confirmation"
            component={EmailConfirmation}
          />

          {/* <PrivateRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            // require2FA={require2FA}
            exact={true}
            path="/verify-2fa"
          >
            <Google2FAPage />
          </PrivateRoute> */}

          <PrivateRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            exact={true}
            path="/dashboard"
          >
            <DashboardPage Component={Dashboard} />
          </PrivateRoute>

          <PrivateRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            exact={true}
            path="/wallet"
          >
            <DashboardPage Component={Wallet} />
          </PrivateRoute>

          <PrivateRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            exact={true}
            path="/profile"
          >
            <DashboardPage Component={Profile} />
          </PrivateRoute>

          <PrivateRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            exact={true}
            path="/deposit-histories"
          >
            <DashboardPage Component={DepositHistory} />
          </PrivateRoute>


          <PrivateRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            exact={true}
            path="/withdraw-histories"
          >
            <DashboardPage Component={WithdrawHistory} />
          </PrivateRoute>


          <PrivateRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            exact={true}
            path="/swap-histories"
          >
            <DashboardPage Component={SwapHistory} />
          </PrivateRoute>

          <PrivateRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            exact={true}
            path="/coupon-sale-histories"
          >
            <DashboardPage Component={CouponSalesHistory} />
          </PrivateRoute>

          <PrivateRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            exact={true}
            path="/task"
          >
            <DashboardPage Component={Task} />
          </PrivateRoute>

          <PrivateRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            exact={true}
            path="/task/youtube/watch"
          >
            <DashboardPage Component={WatchYoutube} />
          </PrivateRoute>


          <PrivateRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            exact={true}
            path="/add-task"
          >
            <DashboardPage Component={MyTask} />

          </PrivateRoute>

          <PrivateRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            exact={true}
            path="/exchange"
          >
            <DashboardPage Component={Exchange} />

          </PrivateRoute>

          <PrivateRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            exact={true}
            path="/referrals"
          >
            <DashboardPage Component={Referrals} />

          </PrivateRoute>


          <PrivateRoute
            loading={userLoading}
            isLogged={isLoggedIn}
            path="/security/2fa"
            component={GoogleAuthenticatorScreen}
          />

          {/* 404 redirect */}
          <Route path="**">
            <Redirect to="/" />
          </Route>
        </Switch>
        {isLoggedIn && <WalletsFetch />}
        {isShownExpSessionModal && this.handleRenderExpiredSessionModal()}
      </>
    );
  }

  private initListener = () => {
    this.reset();
    for (const type of LayoutComponent.eventsListen) {
      document.body.addEventListener(type, this.reset);
    }
  };

  private reset = () => {
    this.setLastAction(Date.now());
  };

  private initInterval = () => {
    this.timer = setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  };

  private setLastAction = (lastAction: number) => {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  };

  private check = () => {
    const { user } = this.props;
    const now = Date.now();
    const timeleft =
      this.getLastAction() + parseFloat(minutesUntilAutoLogout()) * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    if (isTimeout && user.email) {
      if (user.state === "active") {
        this.handleChangeExpSessionModalState(); //uncomment
      }

      this.props.logout();
    }
  };

  private handleSubmitExpSessionModal = () => {
    // const { history } = this.props;
    this.handleChangeExpSessionModalState();
    return <Redirect to={"/login"} />;
  };

  private handleRenderExpiredSessionModal = () => (
    <ExpiredSessionModal
      title="Session expire"
      buttonLabel="X"
      handleChangeExpSessionModalState={this.handleChangeExpSessionModalState}
      handleSubmitExpSessionModal={this.handleSubmitExpSessionModal}
    />
  );

  private handleChangeExpSessionModalState = () => {
    this.setState({
      isShownExpSessionModal: !this.state.isShownExpSessionModal,
    });
  };

  private getLastAction = () => {
    if (localStorage.getItem(STORE_KEY) !== null) {
      return parseInt(localStorage.getItem(STORE_KEY) || "0", 10);
    }

    return 0;
  };
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, RootState> = (
  state
) => ({
  user: selectUserInfo(state),
  isLoggedIn: selectUserLoggedIn(state),
  userLoading: selectUserFetching(state),
  require2FA: selectSignInRequire2FA(state),
  emailVerified: selectEmailVerified(state),
  // rangerState: selectRanger(state),
  // currencies: selectCurrencies(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch
) => ({
  fetchConfigs: () => dispatch(configsFetch()),
  // currenciesFetch: () => dispatch(currenciesFetch()),
  logout: () => dispatch(logoutFetch()),
  userFetch: () => dispatch(userFetch()),
  // walletsReset: () => dispatch(walletsReset()),
  // rangerConnect: (payload) => dispatch(rangerConnectFetch(payload)),
});

// tslint:disable-next-line no-any
const Layout = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutComponent) as any
) as any;

export { Layout };
