import React, { Component } from "react";
import "./input.scss";
import { Addon } from "../Addon";
// import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";

type Props = {
  placeholder?: string;
  type?: string;
  leftAddon?: React.ReactNode;
  rightAddon?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  value?: string;
  handleChangeInput?: (value: string) => void;
  error?: boolean;
  errorMessage?: string;
  id?: string;
  // autocomplete?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
  handleFocusInput?: () => void;
};

interface onChangeEvent {
  target: {
    value: string;
  };
}
interface State {
  v: string;
}
export class Input extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      v: "",
    };
  }

  private handleChangeValue(e: onChangeEvent) {
    this.props.handleChangeInput &&
      this.props.handleChangeInput(e.target.value);
  }
  render() {
    const {
      rightAddon,
      leftAddon,
      type,
      placeholder,
      disabled,
      className,
      value,
      error,
      errorMessage,
      id,
      autoFocus,
      handleFocusInput,
      // autocomplete,
    } = this.props;

    return (
      <>
        <div className={`input-group ${className ? className : ""} ${error ? "error" : ""}`}>
          {leftAddon && <Addon content={leftAddon} className="addon-left" />}
          <input
            className={`input-ol`}
            type={type ? type : "text"}
            placeholder={placeholder}
            disabled={disabled}
            value={value ? value : ""}
            onChange={(e) => this.handleChangeValue(e)}
            id={id ? id : ""}
            autoFocus={autoFocus}
            onFocus={handleFocusInput}
          // autoComplete={autocomplete ? autocomplete : "off"}
          />
          {rightAddon && <Addon content={rightAddon} className="addon-right" />}
          {error && <span className="input-error">{errorMessage}</span>}
        </div>
      </>
    );
  }
}

// Enter Full Name
