import * as React from "react";
import { Button, CodeVerification } from "../";
import { CloseIcon } from "../../assets/icons";
import "./twoFactorAuth.scss";

export interface TwoFactorAuthProps {
  error?: string;
  errorMessage?: string;
  isMobile?: boolean;
  isLoading?: boolean;
  onSubmit: () => void;
  title: string;
  buttonLabel: string;
  message?: string;
  otpCode: string;
  codeFocused?: boolean;
  handleOtpCodeChange: (otp: string) => void;
  handleChangeFocusField?: () => void;
  handleClose2fa: () => void;
}

export const TwoFactorAuthComponent: React.FC<TwoFactorAuthProps> = ({
  errorMessage,
  isMobile,
  isLoading,
  title,
  message,
  codeFocused,
  otpCode,
  buttonLabel,
  handleChangeFocusField,
  onSubmit,
  handleOtpCodeChange,
  handleClose2fa,
}) => {
  const handleEnterPress = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && otpCode.length >= 6) {
        event.preventDefault();
        onSubmit();
      }
    },
    [onSubmit, otpCode]
  );

  return (
    <>
      <>
        <div className="l-form">
          <div className="form login-2fa">
            <div className="form__cros-icon" onClick={handleClose2fa}>
              <CloseIcon className="close-icon" />
            </div>
            <h2>{title || "2FA verification"}</h2>

            <CodeVerification
              code={otpCode}
              onChange={handleOtpCodeChange}
              onSubmit={handleEnterPress}
              codeLength={6}
              type="text"
              placeholder="X"
              inputMode="decimal"
              showPaste2FA={false}
              isMobile={false}
              message={" "}
            />
            {errorMessage && <div className="form__error">{errorMessage}</div>}
            <Button
              disabled={isLoading || otpCode.length < 6}
              onClick={onSubmit}
              className="x100 mt16"
              type="primary"
              lable={
                isLoading ? "Loading..." : buttonLabel ? buttonLabel : "Sign in"
              }
            />
          </div>
        </div>
      </>
    </>
  );
};

export const TwoFactorAuth = React.memo(TwoFactorAuthComponent);
