import React, { useEffect, useState } from "react";
import "./resetpassword.scss";
import { Button, Input } from "../../components";
import { Link } from "react-router-dom";
// import { EMAIL_REGEX } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  changeForgotPasswordFetch,
  selectChangeForgotPasswordSuccess,
} from "../../modules";

import {
  // useParams,
  useHistory,
} from "react-router-dom";
import { appTitle } from "../../api";

export const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirm, setPasswordConfirm] = useState("");
  const [confirmToken, setConfirmToken] = useState("");
  // const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  // const { token } = useParams();

  const history = useHistory();
  const passwordChanged = useSelector(selectChangeForgotPasswordSuccess);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    // Access  param
    // const pEmail = searchParams.get("email");
    const token = searchParams.get("reset_token");
    // const lang = searchParams.get("lang");

    if (token) {
      setConfirmToken(token);
    }

    // if (lang) {
    //   dispatch(changeLanguage(lang.toLowerCase()));
    // }

    // if (!EMAIL_REGEX.test(pEmail!)) {
    //   setErrorEmail("Invalid Email");
    // } else {
    //   setEmail(pEmail!);
    //   setErrorEmail("");
    // }

    if (passwordChanged) {
      history.push("/login");
    }
  }, [passwordChanged, history]);

  const handleChangePassword = (value) => {
    setPassword(value);
  };

  const handleChangePasswordConfirm = (value) => {
    if (password !== value) {
      setErrorPassword("Password do not match");
    } else {
      setErrorPassword("");
    }

    setPasswordConfirm(value);
  };

  const handleSubmit = () => {
    if (errorPassword === "") {
      const payload = {
        reset_password_token: confirmToken,
        password: password,
        confirm_password: password_confirm,
      };
      dispatch(changeForgotPasswordFetch(payload));
    }
  };
  return (
    <>
      <div className="pg-login container">
        {/* <h3>Login</h3> */}
        <Link to="/" className="l-link c-logo">
          <img src={"/img/mini-logo.png"} alt="Mini Logo" className="logo" />
          <span>{appTitle}</span>
        </Link>
        <div className="l-intro">
          <h2>Reset Password</h2>
        </div>

        <Input
          placeholder="New Password"
          value={password}
          type="password"
          handleChangeInput={handleChangePassword}
          id="password"
        />
        <Input
          placeholder="Confirm New Password"
          value={password_confirm}
          type="password"
          handleChangeInput={handleChangePasswordConfirm}
          error={errorPassword !== ""}
          errorMessage={errorPassword}
          className={errorPassword !== "" ? "error" : ""}
          id="password_confirmation"
        />

        <Button
          type="primary"
          lable="Change Password"
          className="x100"
          size="large"
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};
