import {
    HISTORY_DATA,
    HISTORY_ERROR,
    HISTORY_FETCH,
    HISTORY_RESET,
} from './constants';
import { HistoryList } from './types';

export interface HistoryFetchPayload {
    currency?: string;
    page: number;
    type: string;
    limit?: number;
    market?: string;
    time_from?: string;
    time_to?: string;
}

interface HistorySuccessPayload {
    list: HistoryList;
    page: number;
    total: number;
    nextPageExists: boolean;
}

export interface HistoryFetch {
    type: typeof HISTORY_FETCH;
    payload: HistoryFetchPayload;
}

export interface HistoryData {
    type: typeof HISTORY_DATA;
    payload: HistorySuccessPayload;
}

export interface HistoryError {
    type: typeof HISTORY_ERROR;
    payload: HistoryList;
}

// export interface HistoryPushFinish {
//     type: typeof HISTORY_PUSH_FINISH;
//     payload: HistoryList;
// }

export interface HistoryReset {
    type: typeof HISTORY_RESET;
}

export type HistoryActions =
    HistoryFetch
    | HistoryData
    | HistoryError
    | HistoryReset;


export const fetchHistory = (payload: HistoryFetchPayload): HistoryFetch => ({
    type: HISTORY_FETCH,
    payload,
});

export const successHistory = (payload: HistorySuccessPayload): HistoryData => ({
    type: HISTORY_DATA,
    payload,
});

export const failHistory = (payload: HistoryList): HistoryError => ({
    type: HISTORY_ERROR,
    payload,
});

export const resetHistory = (): HistoryReset => ({
    type: HISTORY_RESET,
});


