import {
    //  takeEvery,
      takeLatest, 
    // takeLeading
 } from 'redux-saga/effects';
import { SWAPS_FETCH,
SWAPS_SWAP_FETCH } from '../constants';
import { swapsFetchSaga } from './swapsFetchSaga';
import { swapsSwapFetchSaga } from './swapsSwapFetchSaga';
 

export function* rootSwapsSaga() {
    yield takeLatest(SWAPS_FETCH, swapsFetchSaga); 
    yield takeLatest(SWAPS_SWAP_FETCH, swapsSwapFetchSaga);

}
